import axios from "axios";
import { useSnackbar } from "notistack";
import { parse, stringify } from "qs";
import { useEffect, useState } from "react";
import { ApiContext } from "../context/ApiContext";
import { useAuthContext } from "../context/AuthContext";
const AUTH_TOKEN = "authtoken";

export const getToken = () => {
  return localStorage.getItem(AUTH_TOKEN);
};

export const setToken = (token) => {
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
};

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN);
};

export const APIBASE = "https://api.sommerschein.de";
const axiosClient = axios.create();

const getConfig = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  const token = getToken();
  const baseURL = APIBASE;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return { headers, baseURL };
};

export function getRequest(URL) {
  return axiosClient.get(`${URL}`, getConfig());
  // .then((response) => response);
}

export function postRequest(URL, payload) {
  return axiosClient.post(`${URL}`, payload, getConfig());
  // .then((response) => response);
}

export function putRequest(URL, payload) {
  return axiosClient.put(`${URL}`, payload, getConfig());
  // .then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`${URL}`, getConfig());
  // .then((response) => response);
}

export function uploadRequest(file, params) {
  const formData = new FormData();
  if (file) formData.append("files", file);
  if (params?.alternativeText !== undefined)
    formData.append("alternativeText", params.alternativeText);
  if (params?.caption !== undefined) formData.append("caption", params.caption);
  if (params?.name !== undefined) formData.append("name", params.name);
  const overwiteParam = params?.id ? "?id=" + params.id : "";
  return axiosClient.post("upload" + overwiteParam, formData, {
    ...getConfig(),
    headers: { ...getConfig()?.headers, "Content-Type": "multipart/form-data" },
  });
  // .then((response) => response);
}

export function deleteUploadRequest(upload) {
  return axiosClient.delete("upload/files/" + upload.id, getConfig());
}
//All request will wait 2 seconds before timeout
//axiosClient.defaults.timeout = 2000;
// true is lgged in?
// axiosClient.defaults.withCredentials = false;

const ApiProvider = ({ children }) => {
  const { setUser } = useAuthContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    if (requestCount) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [requestCount, setIsLoading]);

  useEffect(() => {
    var count = 0;
    axiosClient.interceptors.request.clear(); // Removes interceptors from requests
    axiosClient.interceptors.request.use(
      (request) => {
        count++;
        setRequestCount(count);
        return request;
      },
      (error) => {
        enqueueSnackbar("REQUEST:" + error.message, { variant: "error" });
        return Promise.reject(error);
      }
    );
    axiosClient.interceptors.response.clear(); // Removes interceptors from responses
    axiosClient.interceptors.response.use(
      (response) => {
        count--;
        setRequestCount(count);
        if (response.status === 200) {
          if (["put", "post", "delete"].includes(response.config.method)) {
            enqueueSnackbar(response.config.method.toUpperCase() + " OK", {
              variant: "success",
            });
          }
        } else {
          enqueueSnackbar("Status: " + response.status, { variant: "warning" });
        }
        return response;
      },
      (error) => {
        if (error.response.status === 401) {
          removeToken();
          setUser(undefined);
          window.location.reload();
        }
        count--;
        setRequestCount(count);
        enqueueSnackbar("SERVER:" + error.message, { variant: "error" });
        return Promise.reject(error);
      }
    );
  }, [setRequestCount, setIsLoading, setUser, enqueueSnackbar, closeSnackbar]);

  return (
    <ApiContext.Provider
      value={{
        requestCount,
        isLoading,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiProvider;

// Helper functions

export const now = () => {
  return new Date();
};

export const pFuncs = {
  now,
};
function runFunctions(value) {
  if (value instanceof Object) {
    for (let key in value) {
      if (
        value[key] instanceof Object &&
        "$func" in value[key] &&
        value[key]["$func"] in pFuncs
      ) {
        value[key] = pFuncs[value[key]["$func"]]();
      } else {
        runFunctions(value[key]);
      }
    }
  }
  return value;
}
export const parseParams = (paramsString) => {
  let params = parse(paramsString);
  params = runFunctions(params);
  return stringify(params);
};
