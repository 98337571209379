import React from "react";
import ShowLoader from "../../components/showLoader";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { useState } from "react";
import { getRequest } from "../../providers/ApiProvider";
const pageColumns = [
  { field: "id", headerName: "ID" },
  { field: "name" },
  { field: "start_buildup" },
  { field: "end_buildup" },
  { field: "start_event" },
  { field: "end_event" },
  { field: "uuid" },
  { field: "created_at" },
  { field: "updated_at" },
  { field: "published_at" }
];
export default function AdminEventsPage() {
  const [pages, setPages] = useState();
  useEffect(() => {
    // loadPageData();
    getRequest("events?_publicationState=preview")
      .then((response) => {
        setPages(response.data);
      })
      .catch((error) => {
        // setError(error);
      });
  }, []);

  if (pages) {
    return (
      <>
        <DataGrid
          rows={pages}
          columns={pageColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </>
    );
  }
  return <ShowLoader />;
}