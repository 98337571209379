import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import {
  deleteUploadRequest,
  uploadRequest,
} from "../../providers/ApiProvider";
import DropzoneCard from "../dropzone-card";
import AdminUploadSearch from "./admin-upload-search";

// const modalStyle = {
//   position: "absolute",
//   // top: "50%",
//   // left: "50%",
//   // transform: "translate(-50%, -50%)",
//   width: 600,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

export default function AdminUploadForm({
  upload,
  allowDelete,
  onChange = () => {},
  onDelete = () => {},
  onRemove = () => {},
  onClose = () => {},
  onAdd = () => {},
  allowLibary = true,
}) {
  const [currentUpload, setCurrentUpload] = React.useState();
  const [file, setFile] = React.useState();
  const [expanded, setExpanded] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length) {
        const newFile = acceptedFiles[0];
        setFile(newFile);
        setCurrentUpload({
          ...currentUpload,
          name: newFile.name,
          size: newFile.size / 1024,
          mime: newFile.type,
        });
      }
    },
    [currentUpload]
  );

  useEffect(() => {
    setCurrentUpload(upload);
  }, [upload]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleLibarySelect = (selected) => {
    // onChange(selected);
    setFile(null);
    setCurrentUpload(selected);
  };

  const handleClose = () => {
    setExpanded(false);
    onClose();
  };

  const handleSave = () => {
    uploadRequest(file).then((result) => {
      setFile(null);
      onChange(result.data[0]);
    });
  };

  const handleDelete = () => {
    if (onDelete) {
      let deteleTrue = window.confirm("are u shure?");
      if (deteleTrue) {
        deleteUploadRequest(upload).then((result) => {
          onDelete(upload);
        });
      }
    }
  };

  const handleRemove = () => {
    onRemove(currentUpload);
  };

  const handleAdd = () => {
    onAdd(currentUpload);
  };

  if (currentUpload) {
    return (
      <Dialog
        open={!!currentUpload}
        onClose={handleClose}
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{currentUpload.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {currentUpload.mime?.split("/")?.[1]} - {currentUpload.width}x
            {currentUpload.height} - {currentUpload.size}KB
          </DialogContentText>
          {/* <AdminImageCrop></AdminImageCrop> */}
        </DialogContent>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleAccordionChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Upload</Typography>
          </AccordionSummary>
          {/* <AccordionDetails>
            <Typography>
              
            </Typography>
          </AccordionDetails> */}
          <DropzoneCard
            sx={{ minHeight: "100px" }}
            onDrop={handleDrop}
          ></DropzoneCard>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleAccordionChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            disabled={!allowLibary}
          >
            <Typography>Libary</Typography>
          </AccordionSummary>
          <Typography component="div" p={1}>
            {expanded === "panel2" && (
              <AdminUploadSearch
                allowAdd={false}
                allowForm={false}
                allowDelete={false}
                onSelect={handleLibarySelect}
              />
            )}
          </Typography>
        </Accordion>
        <DialogActions>
          {file && <Button onClick={handleSave}>save</Button>}
          {currentUpload.id && <Button onClick={handleAdd}>add</Button>}
          {allowDelete && currentUpload.id && (
            <Button onClick={handleDelete}>delete</Button>
          )}
          {onRemove && <Button onClick={handleRemove}>remove</Button>}
        </DialogActions>
      </Dialog>
    );
  }
}
