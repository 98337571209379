import { Box, TablePagination } from "@mui/material";
import React, { useEffect } from "react";
import AdminUploadList from "../../components/admin/admin-upload-list";
import ShowLoader from "../../components/showLoader";
import { getRequest } from "../../providers/ApiProvider";

export default function AdminUploadSearch({
  allowAdd = true,
  allowForm = true,
  allowDelete = true,
  onSelect = () => {},
}) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sort, setSort] = React.useState("updated_at:DESC");
  const [error, setError] = React.useState();
  const [uploads, setUploads] = React.useState();
  const [count, setCount] = React.useState(0);
  const [listChange, setListChange] = React.useState(0);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelect = (selected) => {
    onSelect(selected);
  };

  useEffect(() => {
    // loadPageData();
    const start = rowsPerPage * page;
    getRequest("/upload/files/count").then((response) =>
      setCount(response.data)
    );
    getRequest(
      "upload/files?_limit=" +
        rowsPerPage +
        "&_start=" +
        start +
        "&_sort=" +
        sort
    )
      .then((response) => {
        if (response.data) {
          setUploads(response.data);
          setError(null);
        } else {
          setError({ message: "Not Found!" });
          setPage(null);
        }
      })
      .catch((error) => {
        setError(error);
      });
  }, [rowsPerPage, page, sort, listChange]);

  if (uploads) {
    return (
      <>
        <Box sx={{ flexGrow: 1 }}>TODO filter</Box>
        <AdminUploadList
          allowAdd={allowAdd}
          allowForm={allowForm}
          allowDelete={allowDelete}
          uploads={uploads}
          onListChange={() => {
            setListChange(new Date());
          }}
          onSelect={handleSelect}
        />
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </>
    );
  } else {
    return <ShowLoader />;
  }
}
