import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { APIBASE } from "../../providers/ApiProvider";
import ShowLoader from "../showLoader";
import AdminUploadForm from "./admin-upload-form";

export default function AdminUploadList({
  uploads,
  onListChange = () => {},
  allowAdd = true,
  allowForm = true,
  allowDelete = false,
  onSelect = () => {},
}) {
  const [upload, setUpload] = React.useState();

  const handleClickUpload = (upload) => {
    if (allowForm) setUpload(upload);
    onSelect(upload);
    // if(onListChange) onListChange([...uploads]);
  };

  const handleOnClose = () => {
    setUpload(null);
  };

  const handleOpenForm = () => {
    setUpload({});
  };

  const handleAdd = (newUpload) => {
    onListChange([...uploads, newUpload]);
  };

  const handleChange = (newUpload) => {
    setUpload(newUpload);
    // filter old and add new
    onListChange([
      ...uploads.filter((up) => {
        return upload !== up;
      }),
      newUpload,
    ]);
  };

  const handleDelete = (up1) => {
    // TODO: handle delete
    onListChange([
      ...uploads.filter((up) => {
        return up1 !== up;
      }),
    ]);
  };

  if (uploads) {
    return (
      <>
        {allowAdd && (
          <Box sx={{ flexGrow: 1, textAlign: "end" }}>
            <Button onClick={handleOpenForm}>add</Button>
          </Box>
        )}
        <Grid container spacing={2}>
          {uploads.map((upload) => {
            // TODO if mime image else...
            return (
              <Grid key={upload.id} item md={3}>
                <Card>
                  <CardActionArea
                    sx={{ p: 1 }}
                    onClick={() => handleClickUpload(upload)}
                  >
                    <Box
                      sx={{
                        maxHeight: "235px",
                        height: "235px",
                        textAlign: "center",
                      }}
                    >
                      <CardMedia
                        key={upload.id}
                        component="img"
                        sx={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                          objectFit: "contain",
                          display: "inline",
                        }}
                        image={
                          APIBASE +
                          (upload?.formats?.thumbnail?.url ||
                            upload?.formats?.small?.url ||
                            upload.url)
                        }
                        alt={
                          upload.alternativeText
                            ? upload.alternativeText
                            : "Kein Bild verfügbar.."
                        }
                      />
                    </Box>
                    <Chip
                      sx={{ float: "right" }}
                      label={upload.mime?.split("/")?.[0]}
                    ></Chip>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      sx={{
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      title={upload.name}
                    >
                      {upload.name}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      component="div"
                      sx={{ textAlign: "left" }}
                    >
                      {upload.mime?.split("/")?.[1]} - {upload.width}x
                      {upload.height} - {upload.size}KB
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        {allowForm && (
          <AdminUploadForm
            upload={upload}
            allowDelete={allowDelete}
            onChange={handleChange}
            onRemove={handleDelete}
            onAdd={handleAdd}
            onClose={handleOnClose}
            onDelete={handleDelete}
          />
        )}
      </>
    );
  } else {
    return <ShowLoader />;
  }
}
