import { Upload } from "@mui/icons-material";
import {
  Card,
  CardActionArea,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useDropzone } from "react-dropzone";
export default function DropzoneCard({ onDrop, sx }) {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  // const {ref, ...rootProps} = getRootProps();
  return (
    <Card sx={{ display: "flex", ...sx }}>
      <CardActionArea
        {...getRootProps()}
        sx={{
          display: "flex",
          textAlign: "center",
        }}
        component={Stack}
        direction="column"
        justifyContent="center"
      >
        <input {...getInputProps()} />
        <Tooltip title="Drag 'n' drop some files here, or click to select files">
          <IconButton>
            <Upload color={isDragActive ? "secondary" : "primary"}></Upload>
          </IconButton>
        </Tooltip>
      </CardActionArea>
    </Card>
  );
}
