import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ShowLoader from '../components/showLoader';
import List from '../components/list.jsx';
import { useTheme } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import _ from "lodash";

import WeatherCard from '../components/weather-card';

import moment from "moment-timezone";

import {
    deleteRequest,
    getRequest,
    postRequest,
    putRequest,
  } from "../providers/ApiProvider";



export default function InfoEventPage() {
    const theme = useTheme();

    const { eventId } = useParams();

    const [eventData, setEventData] = React.useState();
    const [shifts, setShifts] = React.useState();

    const [filter, setFilter] = React.useState();

    const [isLoading, setIsLoading] = useState(true);
    const [neededHelpers, setNeededHelpers] = useState(0);
    const [currentHelpers, setCurrentHelpers] = useState(0);
    const [remainingHours, setRemainingHours] = useState(0);
    const [nowShifts, setNowShifts] = useState(null);
    const [nextShifts, setNextShifts] = useState(null);
    const [missingSunsShifts, setMissingSunsShifts] = useState(null);
    const [weather, setWeather] = useState({ sun: false, rain: false });

    const [error, setError] = useState(null);
    const [events, setEvents] = useState(null);

    const countAssignments = (shifts) =>
    {
        let countedHelpers = 0;
        let currCountedHelpers = 0;
        let countedHours = 0;

        for(let i = 0; i < shifts.length; i++)
        {
            let shiftHours = moment(shifts[i].end).diff(shifts[i].start, "h");
            countedHours += shiftHours * shifts[i].assignments.length;
            countedHelpers += shifts[i].assignments.length;

            for(let j = 0; j < shifts[i].assignments.length; j++)
            {
                if (!shifts[i].assignments[j].sun_id == 0)
                {
                    currCountedHelpers += 1;
                }
            }
        }

        setNeededHelpers(countedHelpers);
        setCurrentHelpers(currCountedHelpers);
        setRemainingHours(countedHours);
    }

    useEffect(() => {
        if (eventId) {
        setIsLoading(true);
        let id = parseInt(eventId, 36) - 1337;
        getRequest("events/" + id + "?_publicationState=preview").then(
            (eventRequest) => {
            setEventData(eventRequest.data);
            console.log(eventRequest.data);
            setFilter({
                start: moment(eventRequest.data.start_buildup),
                end: moment(eventRequest.data.end_buildup),
            });
            }
        );
        }
        setIsLoading(false);
    }, [eventId]);

    useEffect(() => {
        if (eventData) {
            getRequest("so-sy-event-shifts?event_id=" + eventData.id + "&_limit=1000").then(
                (shifts1) => {          
                    const now = moment();
                    const nextHour = now.add(1, "h");
                    const nextShifts = _.filter(shifts1, (item) => {
                        const fromTime = moment(item.start);
                        const toTime = moment(item.end);
                        return fromTime.isSameOrAfter(now);
                    })
                    console.log(nextShifts);
                    setNowShifts();
                    setNextShifts(nextShifts.slice(0,3));
                    setMissingSunsShifts();
                    setShifts(shifts1.data);
                    countAssignments(shifts1.data);
                    console.log(shifts1);
                }
            );
        }
    }, [eventData]);

    if (isLoading) {
        return <ShowLoader />;
    }

    if (shifts && neededHelpers) {
    return (
        <div style={{ marginTop: theme.spacing(4) }}>
            <Grid container spacing={6} justifyContent="center" alignItems="center">
                <Grid item xs={6} sm={3} textAlign="center">
                    <Card sx={{ maxWidth: 345, minHeight: 345 , maxHeight: 345 }}>
                        <CardContent>
                            <Typography variant="h4">Wetter:</Typography>
                            {/*<WeatherCard></WeatherCard>*/}
                            <iframe src="https://www.rainviewer.com/map.html?loc=50.1188,8.6799,10&oFa=0&oC=0&oU=0&oCS=1&oF=0&oAP=0&c=3&o=83&lm=1&layer=radar&sm=1&sn=1&hu=false" width="100%" frameborder="0" style={{border:'0', height: '100vh'}} allowfullscreen></iframe>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3} textAlign="center">
                    <Card sx={{ maxWidth: 345, minHeight: 345 , maxHeight: 345 }}>
                        <CardContent>
                            <Typography variant="h4">Benötigte Sonnen:</Typography>
                            <Typography variant="h2">{neededHelpers}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3} textAlign="center">
                    <Card sx={{ maxWidth: 345, minHeight: 345 , maxHeight: 345 }}>
                        <CardContent>
                            <Typography variant="h4">Arbeitende Sonnen:</Typography>
                            <Typography variant="h2">{currentHelpers}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={6} sm={3} textAlign="center">
                    <Card sx={{ maxWidth: 345, minHeight: 345 , maxHeight: 345 }}>
                        <CardContent>
                            <Typography variant="h4">Verbleibende Stunden:</Typography>
                            <Typography variant="h2">{remainingHours}</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Drei Listen mit Karten */}
                {/* Aktuelle Schichten */}
                <Grid item direction="column" justifyContent="center" alignItems="center" sx={{ m: theme.spacing(3) }}>
                    <Typography variant="h4">Aktuelle Schichten:</Typography>
                    <List cards={shifts}></List>
                </Grid>
                {/* Anstehende Schichten */}
                <Grid item direction="column" justifyContent="center" alignItems="center" sx={{ m: theme.spacing(3) }}>
                    <Typography variant="h4">Nächste Schichten:</Typography>
                    <List cards={nextShifts}></List>
                </Grid>
                {/* Noch dringend benötigte Sonnen - Schichten */}
                <Grid item direction="column" justifyContent="center" alignItems="center" sx={{ m: theme.spacing(3) }}>
                    <Typography variant="h4">Benötigte Sonnen:</Typography>
                    <List cards={shifts}></List>
                </Grid>
            </Grid>
        </div>
    );
    }

    return <ShowLoader />;
}
 