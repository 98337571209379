import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";
import { APIBASE } from "../providers/ApiProvider";
import MDContainer from "./MDContainer";
import EventSearch from "./event-search";
import IconContainer from "./icon-container";
import { ImageCarousel } from "./image-carousel";

export default function DynamicComponent({ componentData }) {
  const key = componentData.__component.split(".")[1];
  const accessRule = componentData.access_rule;
  const { checkPermission } = useAuthContext();

  if (!accessRule || checkPermission(accessRule)) {
    switch (key) {
      case "image":
        return (
          <div
            style={{
              width: "100%",
              textAlign: componentData.align ? componentData.align : "center",
            }}
          >
            <img
              src={
                APIBASE +
                (componentData.src?.[0]?.formats?.large?.url ||
                  componentData.src?.[0]?.url)
              }
              style={{
                alignSelf: "center",
                width: componentData.width,
                height: componentData.height,
              }}
              alt={componentData.alt}
              title={componentData.title}
            ></img>
          </div>
        );
      case "link":
        return (
          <Link
            style={{ color: "white" }}
            title={componentData.title}
            to={componentData.url}
          >
            <Button color="inherit">{componentData.name}</Button>
          </Link>
        );
      case "button":
        return (
          <Button
            component={Link}
            to={componentData.url}
            variant="contained"
            style={{ width: "15rem", backgroundColor: "#F29100" }}
          >
            {componentData.name}
          </Button>
        );
      case "rich-text":
        return <MDContainer>{componentData.content}</MDContainer>;
      case "iframe":
        return (
          <iframe
            title={componentData.title}
            width="100%"
            height="440"
            src={componentData.src}
          ></iframe>
        );
      case "image-carousel":
        return <ImageCarousel gallery={componentData.gallery} />;
      case "icon-container":
        return <IconContainer iconListId={componentData.icon_list.id} />;
      case "spacer":
        return <div style={{ height: componentData.height }}></div>;
      case "event-search":
        return <EventSearch componentData={componentData} />;
      default:
        return <span>DynamicComponent not found!</span>;
    }
  }
}
