import {
    TimelineWrapper,
    TimelineBox,
    TimelineTime,
    TimelineDivider,
    TimelineDividers,
    useTimeline
  } from "planby";
  import moment from "moment-timezone";
  export function Timeline({
    isBaseTimeFormat,
    isSidebar,
    dayWidth,
    hourWidth,
    numberOfHoursInDay,
    offsetStartHoursRange,
    sidebarWidth,
    startDate
  }) {
    const { time, dividers, formatTime } = useTimeline(
      numberOfHoursInDay,
      isBaseTimeFormat
    );
    const renderTime = (index) => (
      <TimelineBox key={index} width={hourWidth}>
        <TimelineTime style={{left:'0'}}>
          {/* {formatTime((index + offsetStartHoursRange)%24).toLowerCase()} */}
          {moment(startDate).add(index,'h').format('dd')} {(index + offsetStartHoursRange)%24}
        </TimelineTime>
        <TimelineDividers>{renderDividers()}</TimelineDividers>
      </TimelineBox>
    );
  
    const renderDividers = () =>
      dividers.map((_, index) => (
        <TimelineDivider key={index} sx={{width:hourWidth}} width={hourWidth} />
      ));
  
    return (
      <TimelineWrapper
        dayWidth={dayWidth}
        sidebarWidth={sidebarWidth}
        isSidebar={isSidebar}
      >
        {time.map((_, index) => renderTime(index))}
      </TimelineWrapper>
    );
  }
  