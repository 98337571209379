import React from 'react';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

const useStyles = makeStyles()((theme) => ({
    root: {
        textAlign: 'center',
        marginBottom: theme.spacing(5),
    },
    title: {
        fontSize: '100px',
        color: 'lightgrey',
        transform: 'translateY(-30px)',
        margin: 0,
    },
    cavemanGif: {
        height: theme.spacing(40),
        width: theme.spacing(75),
        transform: 'translateY(-100px)',
    },
    message: {
        color: 'grey',
        transform: 'translateY(-50px)',
        margin: 0,
    },
}));

export default function UrlNotFound()
{
    const { classes: style } = useStyles();
    const handleRedirect = () => {
        window.location.href = '/';
    }
    return (
        <div>
            <Grid container direction="column" justifyContent="center" alignItems="center" className={style.root}>
                <h1 className={style.title}>404</h1>
                <img src={process.env.PUBLIC_URL + "/404caveman.gif"} alt="caveman" className={style.cavemanGif}/>
                <h3 className={style.message}>Ups.. Diese Seite existiert leider nicht... Drücke den Knopf um zurück zur Übersicht zu gelangen.</h3>
                <Button variant="contained" onClick={handleRedirect} style={{backgroundColor: "#F29100"}}>Zur Übersicht</Button>
            </Grid>
        </div>
    )
}
