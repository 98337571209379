import {
  Button,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { ModalBox } from "../../components/modal-box";
import ShowLoader from "../../components/showLoader";
import {
  getRequest,
  postRequest,
  putRequest,
} from "../../providers/ApiProvider";
const userColumns = [
  { field: "id", headerName: "ID" },
  { field: "username" },
  { field: "email" },
  { field: "provider" },
  { field: "confirmed" },
  { field: "blocked" },
  { field: "created_at" },
  { field: "updated_at" },
];
const sunColumns = [
  { field: "id", headerName: "ID" },
  { field: "name" },
  { field: "user_id" },
];
export default function AdminUsersPage() {
  const [suns, setSuns] = useState();
  const [sun, setSun] = useState();
  const [users, setUsers] = useState();
  const [lastchanged, setLastchanged] = React.useState();
  useEffect(() => {
    // loadPageData();
    getRequest("so-sy-suns")
      .then((response) => {
        setSuns(response.data);
      })
      .catch((error) => {
        // setError(error);
      });
  }, [lastchanged]);

  useEffect(() => {
    // loadPageData();
    getRequest("users")
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        // setError(error);
      });
  }, []);

  const saveSun = React.useCallback(() => {
    if (sun.id) {
      putRequest("so-sy-suns/" + sun.id, sun).then((response) => {
        setSun(response.data);
        setLastchanged(moment());
      });
    } else {
      postRequest("so-sy-suns", sun).then((response) => {
        setSun(response.data);
        setLastchanged(moment());
      });
    }
  }, [sun]);

  const onRowSelectionModelChange = (rowSelectionModel, details) => {
    for (let selectedSun of suns) {
      if (rowSelectionModel[0] === selectedSun.id) {
        setSun(selectedSun);
        break;
      }
    }
  };

  if (suns && users) {
    return (
      <>
        <Typography variant="h3">Sonnen</Typography>
        <Button
          variant="outlined"
          color="success"
          onClick={() => setSun({ user_id: 0 })}
        >
          Hinzufügen
        </Button>
        <DataGrid
          rows={suns}
          columns={sunColumns}
          onRowSelectionModelChange={onRowSelectionModelChange}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
        <Typography variant="h3">Benutzer</Typography>
        <DataGrid
          rows={users}
          columns={userColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[10, 20, 50]}
          checkboxSelection
          disableRowSelectionOnClick
        />
        {sun && (
          <Modal
            open={!!sun}
            onClose={() => setSun(undefined)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalBox variant="modal">
              <Typography variant="h4" sx={{ textDecoration: "underline" }}>
                Sonne ändern
              </Typography>
              <TextField
                id="sunname"
                label="Name"
                variant="outlined"
                value={sun.name}
                onChange={(event) =>
                  setSun({ ...sun, name: event.target.value })
                }
              />
              {/* <Button
                      variant="outlined"
                      color="error"
                      sx={{ mr: 2 }}
                      onClick={deleteShift}
                    >
                      Löschen
                    </Button> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={sun.user_id}
                label="Shift Type"
                onChange={(event) =>
                  setSun({ ...sun, user_id: event.target.value })
                }
              >
                <MenuItem value={0}>Nichts ausgewählt</MenuItem>
                {users &&
                  users.map((user) => {
                    return (
                      <MenuItem key={user.id} value={user.id}>
                        {user.username}
                      </MenuItem>
                    );
                  })}
              </Select>
              <Button
                variant="outlined"
                color="success"
                sx={{ mr: 2 }}
                onClick={saveSun}
              >
                Speichern
              </Button>
            </ModalBox>
          </Modal>
        )}
      </>
    );
  }
  return <ShowLoader />;
}
