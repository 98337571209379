import React from "react";
import { Timetable } from "../components/timetable/Timetable";
// import { useEventShiftsTable } from '../hooks/useEventShiftsTable';
import { useEffect } from "react";
import { useParams } from "react-router";
import { DateTime } from "../components/dateTimeFormat";
import { ModalBox } from "../components/modal-box";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../providers/ApiProvider";
import { Button, MenuItem, Select, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { DateTimePicker } from "@mui/x-date-pickers";
import _ from "lodash";
import moment from "moment-timezone";
import Icon from "../components/icon.component";
import ShowLoader from "../components/showLoader";
import { useAuthContext } from "../context/AuthContext";
import { usePageDataContext } from "../context/PageDataContext";
import { APIBASE } from "../providers/ApiProvider";
import { useMediaQuery } from "@mui/material";
import { maxWidth } from "@mui/system";
import { Link } from "react-router-dom";

const defaultShift = {
  end: moment(),
  start: moment(),
  room_id: 0,
  shift_type_id: 0,
};

const red = 0,
  yellow = 60,
  green = 120,
  turquoise = 180,
  blue = 240,
  pink = 300;

function copyShift(shift, type) {
  let cShift = { ...defaultShift, ...shift, id: undefined };
  if (cShift.assignments) {
    cShift.assignments = cShift.assignments.map((ass) => {
      return { ...ass, id: undefined, sun_id: 0 };
    });
  } else {
    cShift.assignments = [];
  }
  let start = moment(shift.start);
  let end = moment(shift.end);
  let timediff = start.diff(end);
  if (type === "left") {
    cShift.start = start.add(timediff);
    cShift.end = end.add(timediff);
  } else if (type === "right") {
    cShift.start = start.add(-timediff);
    cShift.end = end.add(-timediff);
  }
  return cShift;
}

export default function ShiftsPage() {
  const { editMode, checkPermission, user } = useAuthContext();
  const { eventId } = useParams();
  const [eventData, setEventData] = React.useState();
  // const [error, setError] = React.useState();
  const { setPageDataKey } = usePageDataContext();

  const [channels, setChannels] = React.useState();

  const [epg, setEpg] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const [editShift, setEditShift] = React.useState();
  const [shift, setShift] = React.useState();
  const [shiftTypes, setShiftTypes] = React.useState();
  const [rooms, setRooms] = React.useState();
  const [shifts, setShifts] = React.useState();
  // const [users, setUsers] = React.useState();
  const [lastchanged, setLastchanged] = React.useState();
  const [selectedAssignmentType, setSelectedAssignmentType] = React.useState(1);
  const [assignmentTypes, setAssignmentTypes] = React.useState();
  const [suns, setSuns] = React.useState();
  const [filter, setFilter] = React.useState();
  const theme = useTheme();
  const [ownSun, setOwnSun] = React.useState();

  const smallDisplay = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    setPageDataKey("sonnensystem");
  }, [setPageDataKey]);

  useEffect(() => {
    if (suns && user?.id) {
      for (let sun of suns) {
        if (sun.user_id === user.id) {
          setOwnSun(sun);
          break;
        }
      }
    }
  }, [suns, user]);

  const saveShift = React.useCallback((shift1) => {
    putRequest("so-sy-event-shifts/" + shift1.id, shift1)
      .then((response) => {
        setShift(response.data);
        setLastchanged(moment());
        // enqueueSnackbar("Saved!", "success");
      })
      .catch((e) => {
        // enqueueSnackbar("Error!", "error");
      });
  }, []);

  const saveEditShift = React.useCallback((shift) => {
    if (shift.id) {
      putRequest("so-sy-event-shifts/" + shift.id, shift)
        .then((response) => {
          setEditShift(response.data);
          setLastchanged(moment());
          // enqueueSnackbar("Saved!", "success");
        })
        .catch((e) => {
          // enqueueSnackbar("Error!", "error");
        });
    } else {
      postRequest("so-sy-event-shifts", shift)
        .then((response) => {
          setEditShift(response.data);
          setLastchanged(moment());
          // enqueueSnackbar("Saved!", "success");
        })
        .catch((e) => {
          // enqueueSnackbar("Error!", "error");
        });
    }
  }, []);

  const deleteEditShift = React.useCallback(() => {
    if (shift.id) {
      deleteRequest("so-sy-event-shifts/" + shift.id, shift)
        .then((response) => {
          setEditShift(response.data);
          setLastchanged(moment());
          setEditShift(undefined);
        })
        .catch((e) => {
        });
    } else {
    }
  }, [shift]);

  const deleteShift = React.useCallback((shift1) => {
    if (shift1.id) {
      let detete = window.confirm("Delete?");
      if (detete) {
        deleteRequest("so-sy-event-shifts/" + shift1.id, shift1)
          .then((response) => {
            setLastchanged(moment());
          })
          .catch((e) => {
          });
      }
    } else {
    }
  }, []);

  useEffect(() => {
    getRequest("so-sy-locations").then((locations) => {
      let rooms1 = [];
      for (let location of locations.data) {
        rooms1 = rooms1.concat(location.rooms);
      }
      setRooms(rooms1);
    });
  }, []);

  useEffect(() => {
    getRequest("so-sy-event-shift-types").then((shiftTypesResponse) => {
      let shiftTypes1 = {};
      for (let shiftType of shiftTypesResponse.data) {
        shiftTypes1[shiftType.id] = shiftType;
      }
      setShiftTypes(shiftTypes1);
    });
  }, []);

  useEffect(() => {
    if (eventId) {
      setIsLoading(true);
      let id = parseInt(eventId, 36) - 1337;
      getRequest("events/" + id + "?_publicationState=preview").then(
        (eventRequest) => {
          setEventData(eventRequest.data);
          setFilter({
            start: moment(eventRequest.data.start_event),
            end: moment(eventRequest.data.end_event),
          });
        }
      );
    }
  }, [eventId]);

  useEffect(() => {
    if (eventData) {
      getRequest("so-sy-event-shifts?event_id=" + eventData.id + "&_limit=1000").then(
        (shifts1) => {
          setShifts(shifts1);
        }
      );
    }
  }, [eventData, lastchanged]);

  useEffect(() => {
    getRequest("so-sy-assignment-types").then((response) => {
      setAssignmentTypes(response.data);
    });
  }, []);

  useEffect(() => {
    getRequest("so-sy-suns").then((response) => {
      setSuns(response.data);
    });
  }, []);

  const getRedToGreen = (multiplier) => {
    const g = Math.floor((255 * multiplier));
    const r = Math.floor(255 - (255 * multiplier));
    return '#' + (r.toString(16).length === 1 ? r.toString(16) + '0' : r.toString(16)) + (g.toString(16).length === 1 ? g.toString(16) + '0' : g.toString(16)) + '00';
  };

  // build table
  useEffect(() => {
    if (eventData && shiftTypes && rooms && shifts && filter) {
      // for()
      let roomShiftsConstruct = {};
      for (const shift of shifts.data) {
        if (!roomShiftsConstruct[shift.room_id]) {
          roomShiftsConstruct[shift.room_id] = {};
        }
        if (!roomShiftsConstruct[shift.room_id][shift.shift_type_id]) {
          roomShiftsConstruct[shift.room_id][shift.shift_type_id] = true;
        }
      }
      const channels = [];
      for (let room of rooms) {
        for (const typeId in roomShiftsConstruct[room.id]) {
          channels.push({
            uuid: room.id.toString() + "_" + typeId.toString(),
            title: room.name,
            logo: APIBASE + room.image.formats.thumbnail.url,
            backgroundColor: room.bg_color,
          });
        }
      }
      const epg = [];
      // shiftTypes[shift.shift_type_id].bg_color

      for (let shift of shifts.data) {
        /*console.log(
          "shift",
          shift,
          _.filter(shift.assignments, (ass) => ass.sun_id === 0)
        );*/
        let text = shift.assignments
          .map((item) => _.find(suns, (sun) => sun.id === item.sun_id)?.name)
          .join();
        // todo: more counts
        let assignmentCount = shift.assignments.length;
        let assignmentOpenCount = _.filter(
          shift.assignments,
          (ass) => ass.sun_id === 0
        )?.length;
        let assignmentClosedCount = _.filter(
          shift.assignments,
          (ass) => ass.sun_id !== 0
        )?.length;
        // TODO FILTER OUT
        epg.push({
          id: shift.id,
          title: shift.shift_type_id
            ? shiftTypes[shift.shift_type_id].title
            : "",
          since: shift.start,
          till: shift.end,
          titleExtent: (
            <>
              <Icon
                icon={{ type: "Brightness7", size: "xs", color: "white" }}
              ></Icon>{" "}
              {assignmentClosedCount}
              {" "}
              <Icon
                icon={{ type: "People", size: "xs", color: "white" }}
              ></Icon>{" "}
              {assignmentCount}
            </>
          ),
          text: text,
          channelUuid:
            shift.room_id.toString() + "_" + shift.shift_type_id.toString(),
          background: `linear-gradient(to right, ${getRedToGreen(assignmentClosedCount / assignmentCount)}40, ${getRedToGreen(assignmentClosedCount / assignmentCount)})`,
          // background: hsl_col_color(assignmentClosedCount/assignmentOpenCount,red,green),
          onEdit: checkPermission("role+:editor")
            ? () => setEditShift(shift)
            : undefined,
          onDelete: checkPermission("role+:editor")
            ? () =>
              deleteShift(shift)
            : undefined,
          onClick: () => setShift(shift),
        });
      }
      setEpg(epg);
      setChannels(channels);
      setIsLoading(false);
    }
  }, [eventData, shiftTypes, rooms, shifts, filter, suns, checkPermission, deleteShift]);

  useEffect(() => {
    getRequest("so-sy-event-shift-types").then((shiftTypesResponse) => {
      let shiftTypes1 = {};
      for (let shiftType of shiftTypesResponse.data) {
        shiftTypes1[shiftType.id] = shiftType;
      }
      setShiftTypes(shiftTypes1);
    });
  }, []);

  const setShiftValue = (key, value) => {
    let upShift = { ...shift, [key]: value };
    setShift(upShift);
  };

  const setEditShiftValue = (key, value) => {
    let upShift = { ...editShift, [key]: value };
    setEditShift(upShift);
  };

  if (eventData && shiftTypes && rooms && shifts && channels && epg) {
    // TODO Modal: edit save add button, form
    // TODO Timetable: filters
    return (
      <div component="main" style={{ width: "100vw", maxWidth: "100vw", marginTop: '3vh'}}>
        {checkPermission("role+:editor") && (
          <>
            {" "}
            <Button
              variant="outlined"
              color="success"
              sx={{ mr: theme.spacing(4) }}
              onClick={() =>
                setEditShift(
                  copyShift({
                    event_id: eventData.id,
                    start: moment(eventData.start_buildup),
                    end: moment(eventData.start_buildup).add(4, "h"),
                  })
                )
              }
            >
              Hinzufügen
            </Button>
            {/*<Link
              component="button"
              variant="outlined"
              onClick={() => {
                console.info("I'm a button.");
              }}
              color="info"
            >
              Button Link
            </Link>*/}
            <DateTimePicker
              label="Start"
              value={moment(filter.start)}
              onChange={(newValue) => setFilter({ ...filter, start: newValue })}
              minDateTime={moment(eventData.start_buildup)}
              maxDateTime={moment(eventData.end_buildup)}
              sx={{ mr: theme.spacing(4) }}
            />
            <DateTimePicker
              label="Ende"
              value={moment(filter.end)}
              onChange={(newValue) => setFilter({ ...filter, end: newValue })}
              sx={{ mr: theme.spacing(4) }}
            />
            <Link to={"/event/11e/shifts/" + (user.id + 1337).toString(36)} style={{ color: "white" }}>
              <Button color="inherit">Meine Schichten</Button>
            </Link>
          </>
        )}
        <Timetable
          isLoading={isLoading}
          eventData={eventData}
          epg={epg}
          filter={filter}
          channels={channels}
          style={{ backgroundColor: "red", height: "50vh", maxHeight: smallDisplay ? "5vh" : "50vh", maxWidth: smallDisplay ? "5vh" : undefined }}
        // getEpgProps={getEpgProps}
        // getLayoutProps={getLayoutProps}
        ></Timetable>
        {checkPermission("role+:editor") && editShift && (
          <Modal
            open={!!editShift}
            onClose={() => setEditShift(undefined)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalBox variant="modal">
              <Typography variant="h4" sx={{ textDecoration: "underline" }}>
                Dienst ändern
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ mt: 2 }}
              >
                Ort:
              </Typography>
              {editShift && (
                <>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editShift.room_id}
                    label="Shift Type"
                    size="small"
                    onChange={(event) =>
                      setEditShiftValue("room_id", event.target.value)
                    }
                  >
                    <MenuItem value={0}>Nichts ausgewählt</MenuItem>
                    {rooms &&
                      rooms.map((room) => {
                        return (
                          <MenuItem key={room.id} value={room.id}>
                            {room.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    Dienst-Typ:
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={editShift.shift_type_id}
                    label="Shift Type"
                    size="small"
                    onChange={(event) =>
                      setEditShiftValue("shift_type_id", event.target.value)
                    }
                  >
                    <MenuItem value={0}>Nichts ausgewählt</MenuItem>
                    {shiftTypes &&
                      Object.keys(shiftTypes).map((key) => {
                        return (
                          <MenuItem key={key} value={shiftTypes[key].id}>
                            {shiftTypes[key].title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    Zeiten:
                  </Typography>
                  <DateTimePicker
                    label="Start"
                    value={moment(editShift.start)}
                    onChange={(newValue) =>
                      setEditShiftValue("start", newValue)
                    }
                    minDateTime={moment(eventData.start_buildup)}
                    maxDateTime={moment(eventData.end_buildup)}
                    sx={{ mr: theme.spacing(4) }}
                  />
                  <DateTimePicker
                    label="Ende"
                    value={moment(editShift.end)}
                    onChange={(newValue) => setEditShiftValue("end", newValue)}
                  />
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ mt: 2 }}
                  >
                    Sonnen:
                  </Typography>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedAssignmentType}
                    label="Shift Type"
                    size="small"
                    onChange={(event) =>
                      setSelectedAssignmentType(event.target.value)
                    }
                  >
                    {assignmentTypes &&
                      assignmentTypes.map((assignmentType) => {
                        return (
                          <MenuItem
                            key={assignmentType.id}
                            value={assignmentType.id}
                          >
                            {assignmentType.title}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <Button
                    disabled={false}
                    variant="outlined"
                    color="success"
                    size="small"
                    onClick={() => {
                      setEditShiftValue("assignments", [
                        ...editShift.assignments,
                        {
                          uuid: Math.floor(Math.random() * 10000),
                          assignment_type_id: selectedAssignmentType,
                          sun_id: 0,
                        },
                      ]);
                    }}
                  >
                    +
                  </Button>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    {editShift.assignments &&
                      editShift.assignments.map((assignment) => {
                        return (
                          <Grid
                            sx={{ width: "100%" }}
                            item
                            key={assignment.id || assignment.uuid}
                          >
                            <Grid container>
                              <Grid md={4} item>
                                {assignmentTypes &&
                                  assignmentTypes.map((assignmentType) => {
                                    if (
                                      assignmentType.id ===
                                      assignment.assignment_type_id
                                    ) {
                                      return assignmentType.title;
                                    }
                                    return undefined;
                                  })}
                              </Grid>
                              <Grid md={4} item>
                                {" "}
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  size="small"
                                  value={assignment.sun_id}
                                  label="Shift Type"
                                  onChange={(event) =>
                                    setEditShiftValue("assignments", [
                                      ...editShift.assignments.map((ass) => {
                                        if (ass === assignment) {
                                          return {
                                            ...ass,
                                            sun_id: event.target.value,
                                          };
                                        }
                                        return ass;
                                      }),
                                    ])
                                  }
                                >

                                  <MenuItem value={0}>-vakuum-</MenuItem>
                                  {suns &&
                                    suns.map((sun) => {
                                      return (
                                        <MenuItem key={sun.id} value={sun.id}>
                                          {sun.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>
                              </Grid>
                              <Grid item md={4}>
                                <Button
                                  disabled={false}
                                  variant="outlined"
                                  color="error"
                                  size="small"
                                  onClick={() => {
                                    setEditShiftValue("assignments", [
                                      ...editShift.assignments.filter(
                                        (item) => {
                                          return item.id !== assignment.id;
                                        }
                                      ),
                                    ]);
                                  }}
                                >
                                  -
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                  <Typography variant="div" sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{ mr: 2 }}
                      onClick={() => deleteEditShift(editShift)}
                    >
                      Löschen
                    </Button>
                    <Button
                      variant="outlined"
                      color="success"
                      sx={{ mr: 2 }}
                      onClick={() => saveEditShift(editShift)}
                    >
                      Speichern
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setEditShift(copyShift(editShift, "left"));
                      }}
                    >
                      {"<<"}
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{ ml: 2, mr: 2 }}
                      onClick={() => {
                        setEditShift(copyShift(editShift));
                      }}
                    >
                      Kopieren
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setEditShift(copyShift(editShift, "right"));
                      }}
                    >
                      {">>"}
                    </Button>
                  </Typography>
                </>
              )}
            </ModalBox>
          </Modal>
        )}
        {shift && (
          <Modal
            open={!!shift}
            onClose={() => setShift(undefined)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <ModalBox variant="modal">
              {shift && (
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {shift.shift_type_id &&
                      shiftTypes &&
                      shiftTypes[shift.shift_type_id].title}
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    {shift.shift_type_id &&
                      shiftTypes &&
                      shiftTypes[shift.shift_type_id].description}
                  </Typography>
                  <Typography sx={{ mt: theme.spacing(1) }}>
                    Startet am  <DateTime datetime={shift.start} />.
                  </Typography>

                  <Typography sx={{ mt: theme.spacing(1) }}>
                    Endet am <DateTime datetime={shift.end} />.
                  </Typography>
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%" }}
                  >
                    {shift.assignments &&
                      shift.assignments.map((assignment) => {
                        return (
                          <Grid
                            sx={{ width: "100%" }}
                            item
                            key={assignment.id || assignment.uuid}
                          >
                            <Grid container>
                              <Grid md={4} item>
                                {assignmentTypes &&
                                  assignmentTypes.map((assignmentType) => {
                                    if (
                                      assignmentType.id ===
                                      assignment.assignment_type_id
                                    ) {
                                      return assignmentType.title;
                                    }
                                    return undefined;
                                  })}
                              </Grid>
                              <Grid md={4} item>
                                {" "}
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  size="small"
                                  value={assignment.sun_id}
                                  label="Shift Type"
                                  disabled={true}
                                  onChange={(event) =>
                                    setShiftValue("assignments", [
                                      ...shift.assignments.map((ass) => {
                                        if (ass === assignment) {
                                          return {
                                            ...ass,
                                            sun_id: event.target.value,
                                          };
                                        }
                                        return ass;
                                      }),
                                    ])
                                  }
                                >

                                  <MenuItem value={0}>-vakuum-</MenuItem>
                                  {suns &&
                                    suns.map((sun) => {
                                      if (sun.user_id === user.id) {
                                        return (
                                          <MenuItem key={sun.id} value={sun.id}>
                                            {sun.name}
                                          </MenuItem>
                                        );
                                      }
                                      return null
                                    })}
                                </Select>
                              </Grid>
                              <Grid md={4} item>
                                <Button
                                  disabled={assignment.sun_id !== 0 || !!shift.assignments.filter((item) => item.sun_id === ownSun.id).length}
                                  variant="outlined"
                                  color="success"
                                  size="small"
                                  onClick={() => {
                                    saveShift({
                                      ...shift,
                                      assignments: [
                                        ...shift.assignments.map((item) => item === assignment ? { ...item, sun_id: ownSun.id } : item),
                                      ]
                                    });
                                  }}
                                >
                                  +
                                </Button>
                                <Button
                                  disabled={assignment.sun_id !== ownSun.id}
                                  variant="outlined"
                                  color="error"
                                  size="small"
                                  onClick={() => {
                                    saveShift({
                                      ...shift,
                                      assignments: [
                                        ...shift.assignments.map((item) => item.sun_id === ownSun.id ? { ...item, sun_id: 0 } : item),
                                      ]
                                    });
                                  }}
                                >
                                  -
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                  </Grid>
                </>
              )}
            </ModalBox>
          </Modal>
        )}
      </div>
    );
  }
  return <ShowLoader />;
}
