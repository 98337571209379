import { Button, Grid, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";
import { getDesignTokens, getThemePalette } from "../../App";
import SvelteJSONEditor from "../../components/SvelteJSONEditor";
import { useAuthContext } from "../../context/AuthContext";

export default function AdminDesignSidebar() {
  const { customThemeProps, setCustomThemeProps } = useAuthContext();
  const [customProbs, setCustomProps] = useState({});
  const handleSetThemeProps = () => {
    if (customProbs.mode) {
      setCustomThemeProps(getDesignTokens(customProbs));
    } else {
      setCustomThemeProps(customProbs);
    }
  };
  const theme = useTheme();

  const loadCurrentPaletteProps = () => {
    let data = getThemePalette(theme.palette.mode);
    setCustomProps(data);
  };

  const loadCurrentThemeProps = () => {
    let data = getDesignTokens(getThemePalette(theme.palette.mode));
    // data = theme;
    setCustomProps(data);
  };

  const handleChange = useCallback((event) => {
    setCustomProps(event.json);
  }, []);

  return (
    <Grid
      variant="permanent"
      container
      direction="column"
      sx={{ width: "300px", height: "100vh" }}
    >
      <Grid item md={3}>
        {customProbs && (
          <SvelteJSONEditor
            content={{ json: customProbs }}
            onChange={handleChange}
          />
        )}
      </Grid>

      <Grid item>
        <Grid container direction="column">
          <Button onClick={loadCurrentThemeProps}>load main</Button>

          <Button onClick={loadCurrentPaletteProps}>load palette</Button>
          <Button onClick={handleSetThemeProps}>Set Probs</Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
