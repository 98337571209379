import React from "react";
/*import { makeStyles } from '@mui/material/styles';*/
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useAuthContext } from "../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import DynamicComponent from "./dynamic-component";
import { usePageDataContext } from "../context/PageDataContext";
import { removeToken } from "../providers/ApiProvider";
import { DarkModeOutlined, LightModeOutlined } from "@mui/icons-material";
/*const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 0,
    marginBottom: theme.spacing(12.5)
  },
  hrefLinks: {
    marginRight: theme.spacing(2.5),
    textDecoration: 'none',
    color: 'white',
    '&:hover': {
      color: '#212121',
    },
  },
  imageLogo: {
    height: '5vh',
    width: '5vh',
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));*/

const Navbar = () => {
  const { pageData } = usePageDataContext();
  const { user, setUser, setIsAdminPanelOpen, isAdminPanelOpen, checkPermission, toggleThemePaletteMode, themePaletteMode } = useAuthContext();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    removeToken();
    setUser(undefined);
    handleClose();
    navigate("/signin", { replace: true });
  };
  const handleMePage = () => {
    handleClose();
    navigate("/me", { replace: true });
  };
  const handleToggleAdminPanel = () => {
    setIsAdminPanelOpen(!isAdminPanelOpen);
  };
  if (pageData) {
    if (pageData.error) {
      return <div>404 ERROR</div>;
    } else {
      return (
        <AppBar position="sticky">
          <Toolbar>
            <Typography component="div" style={{ marginTop: "5px" }}>
              <Link title="Home" to="/">
                <img
                  src={process.env.PUBLIC_URL + "/sosch-100x100.png"}
                  alt="Sommerschein Logo"
                  className="logo-navbar"
                  style={{ width: "52px", height: "52px" }}
                ></img>
              </Link>
            </Typography>
            <div style={{ marginLeft: "auto" }}>
              {pageData.main_nav.map((componentData) => {
                return (
                  <DynamicComponent
                    key={componentData.__component + componentData.id}
                    componentData={componentData}
                  ></DynamicComponent>
                );
              })}
              <Button onClick={toggleThemePaletteMode}>
                {themePaletteMode==="dark" ? <LightModeOutlined color="secondary" /> : <DarkModeOutlined color="secondary" />}
              </Button>
              {user ? (
                <>
                  <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    color="inherit"
                  >
                    <Avatar>{user.username.charAt(0)}</Avatar>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleMePage}>Mein Account</MenuItem>
                    {/* <MenuItem onClick={handleMyPage}>My account</MenuItem> */}
                    {checkPermission("role+:editor") && <MenuItem onClick={handleToggleAdminPanel}>Admin Panel</MenuItem>}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </>
              ) : (
                <Link to="/signin" style={{ color: "white" }}>
                  <Button color="inherit">Login</Button>
                </Link>
              )}
            </div>
          </Toolbar>
        </AppBar>
      );
    }
  }
};

export default Navbar;
