import React from "react";
import AdminUploadList from "./admin-upload-list";
import { getRequest, postRequest, putRequest } from "../../providers/ApiProvider";
import { useEffect } from "react";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import ShowLoader from "../showLoader";


export default function AdminGallery({ gallery, onChange }) {
  const [currentGallery, setCurrentGallery] = React.useState();
  const [gallerys, setGallerys] = React.useState();
  useEffect(() => {
    if(gallery){
      setCurrentGallery(gallery);
    }
  }, [gallery]);

  const getGallerys = () => {
    getRequest("gallerys?_publicationState=preview")
    .then((response) => {
      setGallerys(response.data);
    })
    .catch((e) => {
      // enqueueSnackbar("Error!", "error");
    });
  }
  // get gallerys for select
  useEffect(() => {
    getGallerys();
  }, []);

  const saveGallery = () => {
    if(currentGallery.id){
        putRequest("gallerys/" + currentGallery.id, currentGallery)
        .then((response) => {
          setCurrentGallery(response.data);
          onChange(response.data);
          getGallerys();
          // enqueueSnackbar("Saved!", "success");
        })
        .catch((e) => {
          // enqueueSnackbar("Error!", "error");
        });
    } else {
        postRequest("gallerys", currentGallery)
        .then((response) => {
          setCurrentGallery(response.data);
          onChange(response.data);
          getGallerys();
          // enqueueSnackbar("Saved!", "success");
        })
        .catch((e) => {
          // enqueueSnackbar("Error!", "error");
        });
    }
  };

  const handleChange = (key, value) => {
    const newGallery = {
      ...currentGallery,
      [key]: value,
    };
    setCurrentGallery(newGallery);
    onChange(newGallery);
  };

  const handleSelect = (event) => {
    let gal = gallerys.find((gal)=>{return gal.id === event.target.value})
    if(!gal){
        gal = {Images:[], name:'',id:0};
    }
    setCurrentGallery(gal);
    onChange(gal);
  };
  if(currentGallery){
    return (
      <>
        {gallerys &&
        <Select
          labelId={"gal-select-label"+currentGallery.id}
          id={"gal-select"+currentGallery.id}
          // defaultValue={currentGallery.id}
          value={currentGallery.id}
          label="Gallery"
          onChange={handleSelect}
        >
          <MenuItem value={0}>new</MenuItem>
          {gallerys?.map((gal) => {
            return <MenuItem key={gal.id} value={gal.id}>{gal.id} - {gal.name}</MenuItem>;
          })}
        </Select>}
        <Button sx={{ float: "right" }} onClick={saveGallery}>
          save gallery
        </Button>
        <TextField
          margin="normal"
          label="name"
          value={currentGallery?.name}
          color="secondary"
          onChange={(event) => handleChange("name", event.target.value)}
        />
        <AdminUploadList
          uploads={currentGallery?.Images}
          onListChange={(newImages) => {
            handleChange("Images", newImages);
          }}
        />
      </>
    );
  }
  return (
    <div>
      <ShowLoader />
    </div>
  );
}
