import {
  Box,
  Button,
  CardActions,
  Container,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SHA256 from "crypto-js/sha256";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import { postRequest, setToken } from "../../providers/ApiProvider";

const SignInPage = () => {
  const [formSate, setFormSate] = useState("login");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const theme = useTheme();
  const { setUser } = useAuthContext();

  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState("");

  const setUserPlanet = (event) => {
    setEmail(event.target.value);
  };

  /*const test = () => {
    (function(){

      function Stars(numberOfStars, divID){
        var chosenDiv = document.getElementById(divID)  
        chosenDiv.style.display = "none";
        chosenDiv.innerHTML = "";
        function randomFrom(array) {
          return array[Math.floor(Math.random() * array.length)];
        }
        var text = "";
        var i; 
        for (i = 0; i < numberOfStars; i++) {
            var bigRange = Array.apply(null, Array(100)).map(function (_, i) {return i;});
            var smallRange = Array.apply(null, Array(3)).map(function (_, i) {return i;});
            var tenRange = Array.apply(null, Array(5)).map(function (_, i) {return i;});
            var starTwinkleStage = randomFrom("9","13");
            var top = randomFrom(bigRange); 
            var right = randomFrom(bigRange); 
            var width = randomFrom(smallRange);
            text += "<style></style>";
            text += "<div class='stars' style='top:" + top + "%; right: "+ right +"%; width:" + width + "px; height:" + width + "px;";
            text += "animation: twinkling " + starTwinkleStage + "s infinite";
            text += ";box-shadow: 0px 0px 1vw rgba(255, 255, 255, 0.2);'></div>";
            chosenDiv.innerHTML = text;
            chosenDiv.style.display = "block";
        }
    }
      
    // Function(How many stars, id that you want populating)
    Stars(40, "demo");
    
    })();
  }*/

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   setIsLoading(true);
  //   try {
  //     if(){

  //     }
  //     const value = {
  //       identifier: event.target.email.value,
  //       password: event.target.password.value,
  //     };
  //     const response = await postRequest("auth/local", value);
  //     const data = response.data;

  //     if (data?.error) {
  //       throw data?.error;
  //     } else {
  //       // set the token
  //       setToken(data.jwt);
  //       // set the user
  //       setUser(data.user);
  //       navigate("/", { replace: true });
  //     }
  //   } catch (error) {
  //     setError(error?.message ?? "Something went wrong!");
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        if (formSate === "login") {
          const value = {
            identifier: event.target.email.value,
            password: event.target.password.value,
          };
          const response = await postRequest("auth/local", value);
          const data = response.data;

          if (data?.error) {
            throw data?.error;
          } else {
            // set the token
            setToken(data.jwt);
            // set the user
            setUser(data.user);
            navigate("/", { replace: true });
          }
        } else {
          const value = {
            email: event.target.email.value,
            url: "/admin/plugins/users-permissions/auth/reset-password",
          };
          const response = await postRequest("auth/forgot-password", value);
          const data = response.data;

          if (data?.error) {
            throw data?.error;
          } else {
            // to login
          }
        }
      } catch (error) {
        setError(error?.message ?? "Something went wrong!");
      } finally {
        setIsLoading(false);
      }
    },
    [formSate, navigate, setUser]
  );

  const formRef = React.useRef();

  return (
    <Container component="main" maxWidth="sm" sx={{ paddingTop: "2rem" }}>
      <img
        src={
          email
            ? "https://planets.eddson.de/" + SHA256(email)
            : "https://planets.eddson.de/info"
        }
        alt="Planet"
        style={{ zIndex: -1, position: "fixed" }}
      />
      <Card
        sx={{
          marginTop: "2rem",
          backgroundColor: theme.palette.background.paper + "A0",
        }}
      >
        {/* <CardActionArea> */}
        <CardContent>
          <Typography component="h1" variant="h5">
            {formSate === "login" ? "Login" : "Passwort Zurücksetzen"}
          </Typography>
          <Box
            component="form"
            ref={formRef}
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required={false}
              fullWidth
              id="email"
              label="Email Adresse"
              name="email"
              onChange={setUserPlanet}
              autoComplete="email"
            />
            {formSate === "login" && (
              <>
                <TextField
                  margin="normal"
                  required={formSate === "login"}
                  fullWidth
                  name="password"
                  label="Passwort"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Vergiss mich nicht! Zumindest fürs erste.."
                /> */}
              </>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {formSate === "login" ? "Einloggen" : "Mail Senden"}
            </Button>
          </Box>
        </CardContent>
        {/* </CardActionArea> */}
        <CardActions>
          {/* <Grid container display="flex" justifyContent="flex-start">
            <Button size="small" onClick={() => {setFormSate('forgetpw')}}>
              <Link to="#" style={{ color: "white" }}>
                Passwort vergessen?
              </Link>
            </Button>
          </Grid> */}
          {/* <Grid container display="flex" justifyContent="flex-end">
            <Button size="small">
              <Link to='#' style={{ color: 'white' }}>
                Du hast noch keinen Account?<br/>Registriere dich hier!
              </Link>
            </Button>
          </Grid> */}
        </CardActions>
      </Card>
    </Container>
  );
};

export default SignInPage;
