import React, { useEffect, useState } from "react";
// import { makeStyles } from 'tss-react/mui';
import { CardActionArea, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { Grid } from "@mui/material";
import DateTime from "./dateTimeFormat.jsx";
// import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext.js";
import { APIBASE } from "../providers/ApiProvider.jsx";
import Icon from "./icon.component.jsx";

export default function Cards({ card }) {
  const [base36Id, setBase36Id] = useState(null);
  // const { classes: style } = useStyles();
  const { checkPermission } = useAuthContext();
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    setBase36Id((card.id + 1337).toString(36));
  }, [card]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: smallDisplay ? "column" : "row",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(1),
        maxWidth: smallDisplay ? "20vw" : "20vw",
        maxHeight: smallDisplay ? "15vh" : "15vh"
      }}
    >
      <CardActionArea
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: smallDisplay ? "column" : "row"
        }}
      >
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <CardContent
            sx={{ flexGrow: 1, paddingTop: 0, paddingBottom: "0 !important" }}
          >
            <Grid container direction="row">
              <Typography
                component="div"
                variant="h5"
                sx={{ textAlign: "left" }}
              >
                {card.name}
              </Typography>
            </Grid>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                textAlign: "left",
                minHeight: smallDisplay ? 0 : theme.spacing(5),
                marginBottom: 2,
              }}
            >
              {/* Fill in Sonnen plus time */}
              {card.tldr == null
                ? "Keine Beschreibung verfügbar.."
                : card.tldr}
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
    </Card>
  );
}
