import {
  CardContent,
  Container,
  Grid,
  Link,
  useMediaQuery,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import "moment/locale/de";
import React from "react";
import { APIBASE } from "../providers/ApiProvider";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import MDContainer from "./MDContainer";
moment.locale("de");

export default function HappeningCard({ happening }) {
  const theme = useTheme();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startTime = moment.utc(happening.start).tz(timezone).format("HH:mm");
  const endTime = moment.utc(happening.end).tz(timezone).format("HH:mm");
  const date = moment.utc(happening.start).tz(timezone).format("YYYY-MM-DD");
  const day = moment(date).format("dddd");
  const smallDisplay = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card
      sx={{
        padding: theme.spacing(1),
        marginTop: theme.spacing(5),
      }}
    >
      <Grid container direction={smallDisplay ? "column" : "row"}>
        <Grid item sm={12} md={3}>
          <Container disableGutters={true}>
            <Grid container direction="row">
              <Grid item xs={12}>
                <CardMedia
                  component="img"
                  image={
                    happening.image_banner == null
                      ? ""
                      : APIBASE +
                        (happening?.image_banner?.formats?.small?.url ||
                          happening?.image_banner?.url)
                  }
                  alt="Kein Bild verfügbar.."
                />
              </Grid>
              <Grid item md={12}>
                <Container disableGutters={true}>
                  <Stack
                    direction={smallDisplay ? "row" : "column"}
                    spacing={1}
                    sx={{ m: 1 }}
                  >
                    <Chip
                      label={day}
                      size="small"
                      color="primary"
                      sx={{ color: "white" }}
                    />
                    <Chip
                      label={"Beginn: " + startTime + " Uhr"}
                      size="small"
                      color="success"
                      sx={{ color: "white" }}
                    />
                    <Chip
                      label={"Ende: " + endTime + " Uhr"}
                      size="small"
                      color="error"
                      sx={{ color: "white" }}
                    />
                  </Stack>
                </Container>
              </Grid>
            </Grid>
          </Container>
        </Grid>
        <Grid item sm={12} md={9}>
          <CardContent sx={{ px: 1, py: 0 }}>
            {/* <Grid container direction="row"> */}

            <Stack direction="row">
              <Typography
                component="div"
                variant="h5"
                sx={{ textAlign: "left" }}
              >
                {happening.name}
              </Typography>
              {happening.external_link && (
                <Link
                  sx={{ pl: 1 }}
                  title="Link zur Band"
                  target="_blank"
                  href={happening.external_link}
                  color="secondary"
                >
                  <QueueMusicIcon />
                  <OpenInNewIcon />
                </Link>
              )}
              <Chip
                label={happening.event_room.name}
                size="small"
                sx={{
                  my: "auto",
                  ml: "auto",
                  color: happening.event_room.text_color,
                  backgroundColor: happening.event_room.color,
                }}
              />
            </Stack>
            {/* </Grid> */}
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                textAlign: "left",
                minHeight: theme.spacing(20),
                marginBottom: 2,
              }}
            >
              <MDContainer>
                {happening.description == null
                  ? "Keine Beschreibung verfügbar.."
                  : happening.description}
              </MDContainer>
            </Typography>
          </CardContent>
        </Grid>
      </Grid>
    </Card>
  );
}
