import React from "react";
// import { useEventShiftsTable } from '../hooks/useEventShiftsTable';
import { useEffect } from "react";
import { useParams } from "react-router";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../providers/ApiProvider";

import { useTheme } from "@mui/material";
import moment from "moment-timezone";
import ShowLoader from "../components/showLoader";
import { useAuthContext } from "../context/AuthContext";
import { usePageDataContext } from "../context/PageDataContext";

export default function ShiftsUserPage() {
  const { editMode, checkPermission } = useAuthContext();
  const { eventId, userId } = useParams();
  const [eventData, setEventData] = React.useState();
  // const [error, setError] = React.useState();
  const { setPageDataKey } = usePageDataContext();

  const [isLoading, setIsLoading] = React.useState(false);
  const [shift, setShift] = React.useState();
  const [shiftTypes, setShiftTypes] = React.useState();
  const [rooms, setRooms] = React.useState();
  const [shifts, setShifts] = React.useState();
  // const [users, setUsers] = React.useState();
  const [lastchanged, setLastchanged] = React.useState();
  const theme = useTheme();

  useEffect(() => {
    setPageDataKey("sonnensystem");
  }, [setPageDataKey]);

  const saveShift = React.useCallback(() => {
    if (shift.id) {
      putRequest("so-sy-event-shifts/" + shift.id, shift)
        .then((response) => {
          setShift(response.data);
          setLastchanged(moment());
          // enqueueSnackbar("Saved!", "success");
        })
        .catch((e) => {
          // enqueueSnackbar("Error!", "error");
        });
    } else {
      postRequest("so-sy-event-shifts", shift)
        .then((response) => {
          setShift(response.data);
          setLastchanged(moment());
          // enqueueSnackbar("Saved!", "success");
        })
        .catch((e) => {
          // enqueueSnackbar("Error!", "error");
        });
    }
  }, [shift]);

  const deleteShift = React.useCallback(() => {
    if (shift.id) {
      deleteRequest("so-sy-event-shifts/" + shift.id, shift)
        .then((response) => {
          setShift(response.data);
          setLastchanged(moment());
          setShift(undefined);
          // enqueueSnackbar("Saved!", "success");
        })
        .catch((e) => {
          // enqueueSnackbar("Error!", "error");
        });
    } else {
    }
  }, [shift]);

  useEffect(() => {
    getRequest("so-sy-locations").then((locations) => {
      let rooms1 = [];
      for (let location of locations.data) {
        rooms1 = rooms1.concat(location.rooms);
      }
      setRooms(rooms1);
    });
  }, []);

  useEffect(() => {
    getRequest("so-sy-event-shift-types").then((shiftTypesResponse) => {
      let shiftTypes1 = {};
      for (let shiftType of shiftTypesResponse.data) {
        shiftTypes1[shiftType.id] = shiftType;
      }
      setShiftTypes(shiftTypes1);
    });
  }, []);

  useEffect(() => {
    if (eventId && userId) {
      let pEventId = parseInt(eventId, 36) - 1337;
      let pUserId = parseInt(userId, 36) - 1337;
      setIsLoading(true);
      getRequest("events/" + pEventId + "?_publicationState=preview").then(
        (eventRequest) => {
          setEventData(eventRequest.data);
        }
      );
    }
  }, [eventId, userId]);

  useEffect(() => {
    if (eventData) {
      getRequest("so-sy-event-shifts?event_id=" + eventData.id).then(
        (shifts1) => {
          setShifts(shifts1);
        }
      );
    }
  }, [eventData, lastchanged]);

  useEffect(() => {
    getRequest("so-sy-event-shift-types").then((shiftTypesResponse) => {
      let shiftTypes1 = {};
      for (let shiftType of shiftTypesResponse.data) {
        shiftTypes1[shiftType.id] = shiftType;
      }
      setShiftTypes(shiftTypes1);
    });
  }, []);

  const setShiftValue = (key, value) => {
    let upShift = { ...shift, [key]: value };
    setShift(upShift);
  };

  if (eventData && shiftTypes && rooms && shifts) {
    // TODO Modal: edit save add button, form
    // TODO Timetable: filters
    return (
      <div component="main" style={{ width: "100vw", maxWidth: "100vw" }}>
        user shifts
      </div>
    );
  }
  return <ShowLoader />;
}
