import { Box, Container } from "@mui/material";
import React from "react";
import AdminUploadSearch from "../../components/admin/admin-upload-search";

export default function AdminUploadsPage() {
  return (
    <Container component="main" maxWidth="lg" sx={{ my: 4 }}>
      <Box sx={{ flexGrow: 1 }}>
        <AdminUploadSearch />
      </Box>
    </Container>
  );
}
