import { Epg, Layout, useEpg } from "planby";
// import {useTimetable} from './useTimetable.jsx';
import moment from "moment-timezone";
import { ChannelItem } from "./ChannelItem.jsx";
import { ProgramItem } from "./ProgramItem.jsx";
import { Timeline } from "./Timeline.jsx";
//import {theme} from "./theme.js";
import { useTheme } from "@mui/system";
import { useEffect, useState } from "react";
const buildExtraExtraTheme = (theme) => ({
  primary: {
    600: "#1a202c",
    900: theme.palette.background.paper,
  },
  grey: { 300: "#d1d1d1" },
  white: "#fff",
  green: {
    300: "#2c7a7b",
  },
  scrollbar: {
    border: theme.palette.secondary.main,
    thumb: {
      bg: "#e1e1e1",
    },
  },
  loader: {
    teal: "#5DDADB",
    purple: "#3437A2",
    pink: "#F78EB6",
    bg: "#171923db",
  },
  gradient: {
    blue: {
      300: "#002eb3",
      600: "#002360",
      900: "#051937",
    },
  },

  text: {
    grey: {
      300: "#a0aec0",
      500: "#fff",
    },
  },

  timeline: {
    divider: {
      bg: "#fff",
    },
  },
});

function calcDayWihth(date1, date2) {
  return moment(date1).diff(moment(date2), "days") * 2400 || 2400;
}

export const Timetable = ({ isLoading, channels, epg, eventData, filter }) => {
  const [extraTheme, setExtraTheme] = useState();
  const theme = useTheme();
  useEffect(() => {
    setExtraTheme(buildExtraExtraTheme(theme));
  }, [theme]);
  const { getEpgProps, getLayoutProps } = useEpg({
    channels: channels,
    epg: epg,
    dayWidth: calcDayWihth(filter?.start, filter?.end),
    // sidebarWidth: 100,
    //itemHeight: 80,
    //height:600,
    // isSidebar: true,
    // isTimeline: true,
    // isLine: true,
    startDate: filter?.start || moment().toDate(),
    endDate: filter?.end || moment().add(1, "d").toDate(),
    // isBaseTimeFormat: false,
    theme: extraTheme,
  });
  // const { isLoading, getEpgProps, getLayoutProps } = useTimetable();
  return (
    <div>
      <Epg isLoading={isLoading} {...getEpgProps()}>
        <Layout
          {...getLayoutProps()}
          renderTimeline={(props) => <Timeline {...{...props,startDate:filter?.start || moment().toDate()}} />}
          renderProgram={({ program, ...rest }) => (
            <ProgramItem key={program.data.id} program={program} {...rest} />
          )}
          renderChannel={({ channel }) => (
            <ChannelItem key={channel.uuid} channel={channel} />
          )}
        />
      </Epg>
    </div>
  );
};
