// React
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// MUI
import { Box, Button, Container, TextField } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// Components
import DateTime from "../components/dateTimeFormat.jsx";
import HappeningCard from "../components/happening-card";
import ShowLoader from "../components/showLoader.jsx";
// Packages
// import { isMobile } from 'react-device-detect';
import SaveIcon from "@mui/icons-material/Save";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment-timezone";
// import remarkRehype from "remark-rehype";
import MDContainer from "../components/MDContainer.jsx";
import MDEdit from "../components/MDEdit.jsx";
import AdminUploadSigle from "../components/admin/admin-upload-single.jsx";
import EventHappeningEdit from "../components/event-happening-edit.jsx";
import { useAuthContext } from "../context/AuthContext.js";
import { APIBASE, getRequest, putRequest } from "../providers/ApiProvider.jsx";
export default function DynEventPage() {
  const [error, setError] = useState(null);
  const [event, setEvent] = useState(null);
  const { eventId } = useParams();
  // const theme = useTheme();
  const { editMode, checkPermission } = useAuthContext();

  const saveEvent = () => {
    putRequest("events/" + event.id, event)
      .then((response) => {
        setEvent(response.data);
        // enqueueSnackbar("Saved!", "success");
      })
      .catch((e) => {
        // enqueueSnackbar("Error!", "error");
      });
  };

  useEffect(() => {
    if (eventId.length === 10) {
      getRequest(
        "events?uuid=" +
          eventId +
          (checkPermission("role+:member") ? "&_publicationState=preview" : "")
      )
        .then((response) => {
          if (response.data?.length) {
            setEvent(response.data[0]);
          }
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      let id = parseInt(eventId, 36) - 1337;
      getRequest(
        "events/" +
          id +
          (checkPermission("role+:member") ? "?_publicationState=preview" : "")
      )
        .then((response) => {
          if (response.data) {
            setEvent(response.data);
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, [eventId, checkPermission]);

  if (event) {
    if (editMode) {
      return (
        <Container component="main" maxWidth="lg" sx={{ mb: 4, mt: 4 }}>
          <Box
            sx={{ width: "100%", border: "2px solid", mb: 2 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            event.id [{event.id}]
            <Button color="inherit" onClick={saveEvent}>
              <SaveIcon />
            </Button>
          </Box>
          {/* <Button onClick={saveEvent}>save</Button> */}
          {event.image_banner == null ? (
            ""
          ) : (
            <CardMedia
              component="img"
              sx={{ height: "300px" }}
              image={
                APIBASE +
                (event.image_banner?.formats?.large?.url ||
                  event.image_banner.url)
              }
            />
          )}
          <Typography component="div" variant="h4">
            image_banner
          </Typography>
          <AdminUploadSigle
            upload={event.image_banner}
            onChange={(newUpload) =>
              setEvent({ ...event, image_banner: newUpload })
            }
            format={"large"}
          ></AdminUploadSigle>
          <Typography component="div" variant="h4">
            image_profile
          </Typography>
          <AdminUploadSigle
            upload={event.image_profile}
            onChange={(newUpload) =>
              setEvent({ ...event, image_profile: newUpload })
            }
            format={"small"}
          ></AdminUploadSigle>
          <Paper sx={{ padding: 1 }}>
            {/* <Typography
              component="div"
              variant="h4"
              sx={{ textAlign: "left", mb: 2 }}
            >
              {event.name == null ? "N/A" : event.name}
            </Typography> */}
            <TextField
              margin="normal"
              label="name"
              value={event.name}
              onChange={(e) => setEvent({ ...event, name: e.target.value })}
            />
            <MDEdit
              value={event.description}
              onChange={(value) => setEvent({ ...event, description: value })}
              height={500}
            />
            <Typography
              variant="subtitle1"
              component="div"
              sx={{ textAlign: "left" }}
            >
              Das Event geht vom <DateTime datetime={event.start_event} /> bis
              zum <DateTime datetime={event.end_event} />.
            </Typography>
            <Typography component="div" variant="h4">
              Event Time
            </Typography>
            <DateTimePicker
              label="start"
              value={moment(event.start_event)}
              onChange={(newValue) =>
                setEvent({ ...event, start_event: newValue })
              }
            />
            <DateTimePicker
              label="End"
              value={moment(event.end_event)}
              onChange={(newValue) =>
                setEvent({ ...event, end_event: newValue })
              }
            />
            <Typography component="div" variant="h4">
              Event Buildup Time
            </Typography>
            <DateTimePicker
              label="start_buildup"
              value={moment(event.start_buildup)}
              onChange={(newValue) =>
                setEvent({ ...event, start_buildup: newValue })
              }
            />
            <DateTimePicker
              label="end_buildup"
              value={moment(event.end_buildup)}
              onChange={(newValue) =>
                setEvent({ ...event, end_buildup: newValue })
              }
            />
            <Typography
              component="div"
              variant="h5"
              sx={{ textAlign: "left", marginTop: 1, mb: 2 }}
            >
              Ereignisse:
            </Typography>
            <Typography component="div" sx={{ textAlign: "left" }}>
              <Button
                onClick={() =>
                  setEvent({
                    ...event,
                    happenings: [
                      ...event.happenings,
                      { event_room: 0, created_at: moment() },
                    ],
                  })
                }
              >
                Add
              </Button>
              {event.happenings.length === 0 ? (
                <p>Keine Ereignisse gefunden..</p>
              ) : (
                ""
              )}
              {event.happenings.map((happening) => {
                return (
                  <EventHappeningEdit
                    key={happening.id || happening.created_at}
                    happening={happening}
                    onChange={(newHappening) =>
                      setEvent({
                        ...event,
                        happenings: event.happenings.map((curHap) =>
                          curHap === happening ? newHappening : curHap
                        ),
                      })
                    }
                  />
                );
              })}
            </Typography>
          </Paper>
        </Container>
      );
    }
    return (
      <Container component="main" maxWidth="lg" sx={{ mb: 4, mt: 4 }}>
        {event.image_banner == null ? (
          ""
        ) : (
          <CardMedia
            component="img"
            sx={{ height: "300px" }}
            image={
              APIBASE + event.image_banner?.formats?.large?.url ||
              event.image_banner?.url
            }
          />
        )}
        <Paper sx={{ padding: 1 }}>
          <Typography
            component="div"
            variant="h4"
            sx={{ textAlign: "left", mb: 2 }}
          >
            {event.name == null ? "N/A" : event.name}
          </Typography>
          <MDContainer>
            {event.description == null
              ? "Keine Beschreibung verfügbar.."
              : event.description}
          </MDContainer>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ textAlign: "left" }}
          >
            Das Event geht vom <DateTime datetime={event.start_event} /> bis zum{" "}
            <DateTime datetime={event.end_event} />.
          </Typography>
          <Typography
            component="div"
            variant="h5"
            sx={{ textAlign: "left", marginTop: 1, mb: 2 }}
          >
            Ereignisse:
          </Typography>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ textAlign: "left" }}
          >
            {event.happenings.length === 0 ? (
              <p>Keine Ereignisse gefunden..</p>
            ) : (
              ""
            )}
            {event.happenings.map((happening) => {
              return <HappeningCard key={happening.id} happening={happening} />;
            })}
          </Typography>
        </Paper>
      </Container>
    );
  }
  return (
    <div>
      <ShowLoader />
      <p>{error && error.message}</p>
    </div>
  );
}
