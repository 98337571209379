import React from 'react'

import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

export default function ShowLoader()
{
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <CircularProgress size='60px'/>
        </Grid>
    )
}
