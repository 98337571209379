import { useCallback, useEffect, useState } from "react";
import { PageDataContext } from "../context/PageDataContext";
import { getRequest } from "../providers/ApiProvider";
const PageDataProvider = ({ children }) => {
  const [pageDataKey, setPageDataKey] = useState("default");
  const [pageData, setPageData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();

  const handlePageDataKey = useCallback(
    (pageDataKey) => {
      setPageDataKey(pageDataKey);
    },
    [setPageDataKey]
  );

  useEffect(() => {
    if (!pageData || pageData.name !== pageDataKey) {
      setIsLoading(true);
      getRequest("page-data?name=" + pageDataKey)
        .then((response) => {
          if (response.data.length) {
            if (
              response.data[0].name === pageDataKey &&
              (!pageData || response.data[0].id !== pageData.id)
            ) {
              setPageData(response.data[0]);
            }
          }
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [pageDataKey, pageData]);

  return (
    <PageDataContext.Provider
      value={{ pageData, setPageDataKey: handlePageDataKey, isLoading, error }}
    >
      {children}
    </PageDataContext.Provider>
  );
};

export default PageDataProvider;
