import React, { useEffect, useState } from "react";
import EventList from "./event-list.jsx";
import ShowLoader from "./showLoader.jsx";
import { getRequest, parseParams } from "../providers/ApiProvider.jsx";

export default function EventSearch({ componentData }) {
  const [events, setEvents] = useState(null);
  useEffect(() => {
    getRequest("events" + (componentData.params?'?'+parseParams(componentData.params):'')).then((response) => {
      if (response.data?.length) {
        setEvents(response.data);
      }
    });
  }, [componentData]);
  if (events) {
    return <EventList eventcards={events} />;
  }
  return <ShowLoader />;
}
