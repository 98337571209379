import React from "react";
import ShowLoader from "../../components/showLoader";

export default function AdminDashboardPage() {


    if(true){
      return(
        <>
          adminDashboard
        </>
      )
    }
    return (
      <ShowLoader />
    );
}