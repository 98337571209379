import {
  AutoStories,
  Brush,
  DeveloperBoard,
  Event,
  Facebook,
  Instagram,
  Link,
  LocationOn,
  People,
  Upload,
  Brightness5,
  Brightness7,
  Flare,
  WbSunny

} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import React, { createElement } from "react";

const MuiIcons = {
  Facebook,
  Instagram,
  LocationOn,
  People,
  Event,
  AutoStories,
  Link,
  DeveloperBoard,
  Upload,
  Brush,
  Brightness5,
  Brightness7,
  Flare,
  WbSunny
};
function Icon({ icon }) {
  if (MuiIcons[icon?.type]) {
    return (
      <Tooltip title={icon.title}>
        {createElement(MuiIcons[icon.type], {
          color: icon.color,
          fontSize: icon.size,
        })}
      </Tooltip>
    );
  } else {
    return <span>Icon not found</span>;
  }
}
export default Icon;
