import React, { useEffect, useState } from "react";
// import { makeStyles } from 'tss-react/mui';
import { CardActionArea, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import { Grid } from "@mui/material";
import DateTime from "./dateTimeFormat.jsx";
// import { isMobile } from 'react-device-detect';
import { Link } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext.js";
import { APIBASE } from "../providers/ApiProvider.jsx";
import Icon from "./icon.component.jsx";

// const useStyles = makeStyles()((theme) => ({
//     root: {
//         flexgrow: 1,
//         minWidth: '60%',
//         maxWidth: '60%',
//         marginBottom: theme.spacing(5),
//     },
//     card: {
//         display: 'flex',
//         flexDirection: 'row',
//         backgroundColor: '#424242',
//         padding: theme.spacing(1),
//         color: '#ffffff',
//         minHeight: theme.spacing(30),
//         maxWidth: theme.spacing(140),
//         minWidth: theme.spacing(140),
//         marginBottom: theme.spacing(5)
//     },
//     cardMobile: {
//         display: 'flex',
//         flexDirection: 'row',
//         backgroundColor: '#424242',
//         padding: theme.spacing(1),
//         color: '#ffffff',

//         marginBottom: theme.spacing(5)
//     }
// }));

export default function EventCard({ eventcard }) {
  const [base36Id, setBase36Id] = useState(null);
  // const { classes: style } = useStyles();
  const { checkPermission } = useAuthContext();
  const theme = useTheme();
  const smallDisplay = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    setBase36Id((eventcard.id + 1337).toString(36));
  }, [eventcard]);
  // if (isMobile) {
  //     return (
  //         <Card className={style.cardMobile} sx={{display: 'flex', flexDirection: 'row'}}>
  //             <Grid container direction="column" justifyContent="center" alignItems="center">
  //                 <Grid container sx={{minHeight: '235px', maxHeight: '235px'}}>
  //                     <CardMedia
  //                         component="img"
  //                         sx={{display: 'flex', height: '235px'}}
  //                         style={{objectFit: 'cover', fontSize: '15px'}}
  //                         image={eventcard.image_profile == null ? "" : APIBASE + eventcard.image_profile.formats.small.url}
  //                         alt="Kein Bild verfügbar.."
  //                     />
  //                 </Grid>
  //                 <CardActionArea href={'/event/' + eventcard.uuid + '/'}>
  //                     <Box sx={{ display: 'flex'}}>
  //                         <CardContent sx={{ flex: '1 0 auto', maxWidth: '97%', paddingLeft: 0, paddingTop: 0, paddingBottom: '0 !important'}}>
  //                                 <Grid container direction="row">
  //                                     <Typography component="div" variant="h5" sx={{textAlign: 'left'}}>
  //                                         {eventcard.name}
  //                                     </Typography>
  //                                 </Grid>
  //                                 <Typography className='text-ellipsis--2' variant="subtitle1" component="div" sx={{textAlign: 'left', maxHeight: theme.spacing(20), minHeight: theme.spacing(20), marginBottom: 2}}>
  //                                     {eventcard.tldr == null ? "Keine Beschreibung verfügbar.." : eventcard.tldr}
  //                                 </Typography>
  //                                 <Typography component="div" sx={{fontSize: '12px', color: 'gray'}}>
  //                                     Veröffentlicht am <DateTime datetime={eventcard.published_at}/>.
  //                                 </Typography>
  //                         </CardContent>
  //                     </Box>
  //                 </CardActionArea>
  //             </Grid>
  //         </Card>
  //     )
  // }
  // className={style.card}
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: smallDisplay ? "column" : "row",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(5),
      }}
    >
      <CardActionArea
        component={Link}
        to={"/event/" + base36Id + "/"}
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: smallDisplay ? "column" : "row",
          // marginRight:theme.spacing(3)
        }}
      >
        <CardMedia
          component="img"
          sx={{
            width: smallDisplay ? undefined : "235px",
            minWidth: "235px",
            height: "235px",
            alignSelf: "center",
            // marginRight: "24px"
          }}
          style={{ objectFit: "cover", fontSize: "15px" }}
          image={
            eventcard.image_profile == null
              ? ""
              : APIBASE + eventcard.image_profile.formats.small.url
          }
          alt="Kein Bild verfügbar.."
        />
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          <CardContent
            sx={{ flexGrow: 1, paddingTop: 0, paddingBottom: "0 !important" }}
          >
            <Grid container direction="row">
              <Typography
                component="div"
                variant="h5"
                sx={{ textAlign: "left" }}
              >
                {eventcard.name}
              </Typography>
            </Grid>
            <Grid container direction="row">
              <Typography
                component="div"
                variant="p"
                sx={{ textAlign: "left" }}
              >
                Start: <DateTime datetime={eventcard.start_event} />
              </Typography>
            </Grid>
            <Typography
              variant="subtitle1"
              component="div"
              sx={{
                textAlign: "left",
                minHeight: smallDisplay ? 0 : theme.spacing(20),
                marginBottom: 2,
              }}
            >
              {eventcard.tldr == null
                ? "Keine Beschreibung verfügbar.."
                : eventcard.tldr}
            </Typography>
            <Typography
              component="div"
              sx={{ fontSize: "12px", color: "gray" }}
            >
              Veröffentlicht am <DateTime datetime={eventcard.published_at} />.
            </Typography>
          </CardContent>
        </Box>
      </CardActionArea>
      {checkPermission("role+:authenticated") && (
        <Box
          style={{
            display: "flex",
            flexDirection: smallDisplay ? "column" : "row",
          }}
        >
          <Link
            style={{ alignSelf: "flex-end" }}
            to={"event/" + base36Id + "/shifts"}
          >
            <Icon
              icon={{ type: "People", size: "xs", color: "primary" }}
            ></Icon>
          </Link>
        </Box>
      )}
    </Card>
  );
}
