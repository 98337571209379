import { createContext, useContext } from "react";

export const PageDataContext = createContext({
  pageData: undefined,
  setPageData: () => {},
  pageDataKey: undefined,
  setPageDataKey: () => {},
  isLoading: true,
  error: undefined
});

export const usePageDataContext = () => useContext(PageDataContext);