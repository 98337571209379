import { Delete, Edit } from "@mui/icons-material";
import { Fab, Stack } from "@mui/material";
import {
  ProgramBox,
  ProgramContent,
  ProgramFlex,
  ProgramImage,
  ProgramStack,
  ProgramText,
  ProgramTitle,
  useProgram,
} from "planby";
import { useState } from "react";

export const ProgramItem = ({ program, ...rest }) => {
  const [isHover, setIsHover] = useState(false);
  const { styles, formatTime, set12HoursTimeFormat, isLive, isMinWidth } =
    useProgram({
      program,
      ...rest,
    });

  const { data } = program;
  const { image, title, since, till, onClick, background, onEdit, onDelete } =
    data;

  const sinceTime = formatTime(since, set12HoursTimeFormat()).toLowerCase();
  const tillTime = formatTime(till, set12HoursTimeFormat()).toLowerCase();

  return (
    <ProgramBox
      width={styles.width}
      style={styles.position}
      onClick={onClick}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <ProgramContent
        width={styles.width}
        isLive={isLive}
        style={{ background }}
      >
        <ProgramFlex>
          <div
            style={{
              display: "flex",
              position: "absolute",
              right: 1,
              top: 1,
              zIndex: 2,
            }}
          >
            {isHover && (
              <Stack direction="row" alignItems="center" spacing={2}>
                {onEdit && (
                  <Fab
                    size="small"
                    onClick={(event) => {
                      onEdit();
                      event.stopPropagation();
                    }}
                    sx={{ border: 1 }}
                    color="secondary"
                    aria-label="edit"
                    component="label"
                  >
                    <Edit />
                  </Fab>
                )}
                {onDelete && (
                  <Fab
                    size="small"
                    onClick={(event) => {
                      onDelete();
                      event.stopPropagation();
                    }}
                    sx={{ border: 1 }}
                    color="secondary"
                    aria-label="delete"
                    component="label"
                  >
                    <Delete />
                  </Fab>
                )}
              </Stack>
            )}
          </div>
          {/* {isLive && isMinWidth && <ProgramImage src={image} alt="Preview" />} */}
          <ProgramStack>
            {/* TODO: Move to shifts page (props) */}
            <ProgramTitle style={{fontSize:'18px'}}>
              {title} {data.titleExtent}
            </ProgramTitle>
            <ProgramText style={{fontSize:'14px'}}>
              {sinceTime} - {tillTime} {data.text}
            </ProgramText>
          </ProgramStack>
        </ProgramFlex>
      </ProgramContent>
    </ProgramBox>
  );
};
