// image upload
import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { APIBASE } from "../providers/ApiProvider";
// import { transformImageUri } from "../providers/ApiProvider";

export const BlogImage = (props) => {
  let margin = "0";
  if (props.alt?.includes("{center}")) {
    margin = "0 auto";
  }
  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...props}
      style={{ maxWidth: "100%", display: "block", margin: margin }}
    />
  );
};

export function transformImageUri(src, alt, title) {
  if (src.startsWith("/uploads/")) return APIBASE + src;
  return src;
}

export default class MDContainer extends React.Component {
  components = { img: BlogImage };
  render() {
    return (
      <ReactMarkdown
        {...this.props}
        rehypePlugins={[rehypeRaw]}
        transformImageUri={transformImageUri}
        components={this.components}
      />
    );
  }
}
