import React from "react";
import ReactDOM from "react-dom/client";
// import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AuthProvider from "./providers/AuthProvider";
import PageDataProvider from "./providers/PageDataProvider";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import ApiProvider from "./providers/ApiProvider";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "./components/fallback-component";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onError={(error, errorInfo) =>
        enqueueSnackbar("index:" + error.message, { variant: "error" })
      }
      onReset={() => {
        // reset the state of your app
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
      <SnackbarProvider>
        <AuthProvider>
          <ApiProvider>
            <PageDataProvider>
              <App />
            </PageDataProvider>
          </ApiProvider>
        </AuthProvider>
      </SnackbarProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
reportWebVitals();