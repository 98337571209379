import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import TestPage from "./pages/test.page";

import Navbar from "./components/navbar";
import DynamicPage from "./pages/dyn.page";
import HelfertypesPage from "./pages/helfertypes.page";
import ShiftsPage from "./pages/shifts.page";
import SignInPage from "./pages/user/sign-in.page";

import { CssBaseline } from "@mui/material";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import FallbackComponent from "./components/fallback-component";
import DynEventPage from "./pages/dyn-event.page";
import InfoEventPage from "./pages/event-info.page";
// ADMIN
import Lodash from "lodash";
import { useAuthContext } from "./context/AuthContext";
import AdminDashboardPage from "./pages/admin/admin-dashboard.page";
import AdminDesignSidebar from "./pages/admin/admin-design-sidebar";
import AdminEventsPage from "./pages/admin/admin-events.page";
import AdminLinksPage from "./pages/admin/admin-links.page";
import AdminPagesPage from "./pages/admin/admin-pages.page";
import AdminSidebar from "./pages/admin/admin-sidebar";
import AdminUploadsPage from "./pages/admin/admin-uploads.page";
import AdminUsersPage from "./pages/admin/admin-users.page";
import ShiftsUserPage from "./pages/shifts-user.page";
import MePage from "./pages/user/me.page";

export const getThemePalette = (mode) => {
  const palette = {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          // blue light theme
          //   primary: {
          //     main: "#18616a",
          //   },
          //   secondary: {
          //     main: "#F29100",
          //   },
          //   background: {
          //     default: "rgba(125,196,205,0.6)",
          //     paper: "rgba(125,196,205,0.9)",
          //   },
          //   text: {
          //     primary: "#000000",
          //   },
          // primary: {
          //   main: "#FF9800",
          // },
          // secondary: {
          //   main: "#00BCD4",
          // },
          // background: {
          //   default: "#FFFFFF",
          //   paper: "#F5F5F5",
          // },
          // text: {
          //   primary: "#212121",
          // },
          mode: "light",
          primary: {
            main: "#db6d0a",
          },
          secondary: {
            main: "#000000",
          },
          background: {
            default: "#fbe3976c",
            paper: "#f29109",
          },
          text: {
            primary: "#212121",
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#212121",
          },
          secondary: {
            main: "#F29100",
          },
          background: {
            default: "#3B3B3B",
            paper: "#424242",
          },
          text: {
            primary: "#ffffff",
          },
          // primary: {
          //   main: "#212121",
          // },
          // secondary: {
          //   main: "#F29100",
          // },
          // background: {
          //   default: "#3B3B3B",
          //   paper: "#424242",
          // },
          // text: {
          //   primary: "#ffffff",
          // },

          // old values
          // primary: {
          //   main: "#006064",
          // },
          // secondary: {
          //   main: "#FF9800",
          // },
          // background: {
          //   default: "#212121",
          //   paper: "#303030",
          // },
          // text: {
          //   primary: "#FFFFFF",
          // },
        }),
  };
  return palette;
};

export const getDesignTokens = (palette) => {
  // Returns
  return {
    palette,
    components: {
      MuiBox: {
        variants: [
          {
            props: { variant: "modal" },
            styleOverrides: {
              root: {
                backgroundColor: "red",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                border: "2px solid",
                boxShadow: 24,
                p: 4,
              },
            },
          },
        ],
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: palette.primary.main,
            backgroundImage:
              "linear-gradient(rgba(255, 255, 255, 0.00), rgba(255, 255, 255, 0.09))",
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          display: "flex",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
        body: {
          display: "flex",
          flex: "1 1 auto",
          flexDirection: "column",
          minHeight: "100%",
          width: "100%",
        },
      },
    },
  };
};

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const {
    isAdminPanelOpen,
    themePaletteMode,
    customThemeProps,
    isDesignSidebarOpen,
  } = useAuthContext();

  const theme = React.useMemo(() => {
    const tp = Lodash.merge(
      getDesignTokens(getThemePalette(themePaletteMode)),
      customThemeProps
    );
    let t = createTheme(tp);
    // t = responsiveFontSizes(t);
    return t;
  }, [themePaletteMode, customThemeProps]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div style={{ minHeight: "100vh", display: "flex", flexFlow: "row" }}>
          <BrowserRouter>
            {isAdminPanelOpen && <AdminSidebar></AdminSidebar>}
            {isDesignSidebarOpen && <AdminDesignSidebar></AdminDesignSidebar>}

            <div style={{ display: "flex", flexFlow: "column", flexGrow: 1 }}>
              <Navbar />
              <ErrorBoundary
                FallbackComponent={FallbackComponent}
                onError={(error, errorInfo) =>
                  enqueueSnackbar("app:" + error.message, { variant: "error" })
                }
                onReset={() => {
                  // reset the state of your app
                }}
              >
                <Routes>
                  <Route>
                    {/* <Route exact path="/" element={<IndexPage />}></Route> */}
                    <Route
                      exact
                      path="/event/:eventId/shifts"
                      element={<ShiftsPage />}
                    />
                    <Route
                      exact
                      path="/event/:eventId/shifts/:userId"
                      element={<ShiftsUserPage />}
                    />
                    <Route
                      exact
                      path="/helpertypes/"
                      element={<HelfertypesPage />}
                    />
                    <Route exact path="/signin" element={<SignInPage />} />
                    <Route exact path="/me" element={<MePage />} />
                    <Route exact path="/test/" element={<TestPage />}></Route>
                    <Route
                      exact
                      path="/event/:eventId"
                      element={<DynEventPage />}
                    />
                    <Route
                      exact
                      path="/event/:eventId/info"
                      element={<InfoEventPage />}
                    />
                    <Route
                      exact
                      path="admin/dashboard"
                      element={<AdminDashboardPage />}
                    />
                    <Route
                      exact
                      path="admin/users"
                      element={<AdminUsersPage />}
                    />
                    <Route
                      exact
                      path="admin/links"
                      element={<AdminLinksPage />}
                    />
                    <Route
                      exact
                      path="admin/pages"
                      element={<AdminPagesPage />}
                    />
                    <Route
                      exact
                      path="admin/events"
                      element={<AdminEventsPage />}
                    />
                    <Route
                      exact
                      path="admin/uploads"
                      element={<AdminUploadsPage />}
                    />
                    <Route path="/*" element={<DynamicPage />} />
                  </Route>
                </Routes>
              </ErrorBoundary>
              <Footer />
            </div>
          </BrowserRouter>
        </div>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
