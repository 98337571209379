import React from "react";
import { usePageDataContext } from "../context/PageDataContext";
import DynamicComponent from "./dynamic-component";
import ShowLoader from "./showLoader";
// import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar } from "@mui/material";

export default function Footer() {
  // const theme = useTheme();
  const { pageData } = usePageDataContext();

  if (pageData) {
    if (pageData.error) {
      return <div>404 ERROR</div>;
    } else {
      return (
        <AppBar
          position={"relative"}
          sx={{ marginTop: 'auto', bottom: 0, alignItems: "center"}}
        >
          <Toolbar>
            {pageData.footer.map((componentData) => {
              return (
                <DynamicComponent
                  key={componentData.__component + componentData.id}
                  componentData={componentData}
                ></DynamicComponent>
              );
            })}
          </Toolbar>
        </AppBar>
      );
    }
  } else {
    <ShowLoader />;
  }
}
