import React from 'react';
import Cards from './card.jsx';

export default function List({cards}) {
    return (
        cards.map(card => {
            return (<Cards key={card.id} card={card} />)
        })
    )
}
