import React from 'react';
import EventCard from './event-card.jsx';

export default function EventList({eventcards}) {
    return (
        eventcards.map(eventcard => {
            return (<EventCard key={eventcard.id} eventcard={eventcard} />)
        })
    )
}
