import { Grid, Typography } from "@mui/material";
import { ChannelBox, ChannelLogo } from "planby";

export const ChannelItem = ({ channel }) => {
  const { position, logo, title, backgroundColor } = channel;
  return ( 
    <ChannelBox {...position}>
      {/* Overwrite styles by add eg. style={{ maxHeight: 52, maxWidth: 52,... }} */}
      {/* Or stay with default styles */}
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <ChannelLogo
          src={logo}
          alt="Logo"
          style={{ maxHeight: 52, maxWidth: 52, backgroundColor }}
        />
        <Typography>{title}</Typography>
      </Grid>
    </ChannelBox>
  );
};
