// image upload
import MDEditor from "@uiw/react-md-editor";
import React from "react";
import rehypeRaw from "rehype-raw";
import { BlogImage, transformImageUri } from "./MDContainer";
// import { transformImageUri } from "../providers/ApiProvider";

export default class MDEdit extends React.Component {
  components = { img: BlogImage };
  render() {
    return (
      <MDEditor
        {...this.props}
        style={{ width: "100%" }}
        previewOptions={{
          transformImageUri: transformImageUri,
          rehypePlugins: [rehypeRaw],
          components: { img: BlogImage },
        }}
      />
    );
  }
}
