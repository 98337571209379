import { KeyboardArrowLeft } from "@mui/icons-material";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { Button, MobileStepper } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import React, { useCallback, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import "../css/embla.css";
import { APIBASE } from "../providers/ApiProvider";
import ShowLoader from "./showLoader";
const useStyles = makeStyles()((theme) => ({
  dotActive: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const options = {
  loop: true,
};

export const ImageCarousel = ({ gallery }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
  const { classes: style } = useStyles();

  const theme = useTheme();

  const [activeStep, setActiveStep] = React.useState(0);

  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  );
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  );
  //   const scrollTo = useCallback(
  //     (index) => emblaApi && emblaApi.scrollTo(index),
  //     [emblaApi],
  //   )

  useEffect(() => {
    if (!emblaApi) return;
    emblaApi.on("select", () => setActiveStep(emblaApi.selectedScrollSnap()));
  }, [emblaApi, emblaRef]);

  if (gallery) {
    const img_test = gallery.Images;
    const maxSteps = img_test.length;

    return (
      <Box sx={{ flexgrow: 1 }}>
        <div className="embla">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {img_test.map((step, index) => (
                <div className="embla__slide" key={index}>
                  <div className="embla__slide__number">
                    <span>{index + 1}</span>
                  </div>
                  <img
                    className="embla__slide__img"
                    src={APIBASE + (step?.formats?.large?.url || step.url)}
                    alt={step.name}
                  />
                </div>
              ))}
            </div>
          </div>
          <MobileStepper
            varient="dots"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            classes={{ dotActive: style.dotActive }}
            nextButton={
              <Button size="small" onClick={scrollNext} color="secondary">
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={scrollPrev} color="secondary">
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
            }
            sx={{ backgroundColor: theme.palette.background.paper }}
          />
        </div>
      </Box>
    );
  }
  return (
    <div>
      <ShowLoader />
    </div>
  );
  //   return (
  //     <div className="embla" ref={emblaRef}>
  //       {" "}
  //       <div className="embla__container">
  //         {" "}
  //         <div className="embla__slide">Slide 1</div>{" "}
  //         <div className="embla__slide">Slide 2</div>{" "}
  //         <div className="embla__slide">Slide 3</div>{" "}
  //       </div>{" "}
  //     </div>
  //   );
};
