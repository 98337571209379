import React, { useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useEffect, useCallback } from "react";
import { getRequest } from "./ApiProvider";
import { getToken } from "./ApiProvider";
import { useMediaQuery } from "@mui/material";
const getRoleWeight = (role) => {
  switch (role) {
    case "public":
      return 1;
    case "authenticated":
      return 2;
    case "member":
      return 3;
    case "editor":
      return 4;
    case "admin":
      return 5;
    default:
      return 0;
  }
};

const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState();
  const [roleData, setRoleData] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isAdminPanelOpen, setIsAdminPanelOpen] = useState();
  const [isDesignSidebarOpen, setIsDesignSidebarOpen] = useState(false);
  const [editMode, setEditMode] = useState();
  // const [prefersDarkMode, setPrefersDarkMode] = useState(useMediaQuery('(prefers-color-scheme: dark)'));
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [themePaletteMode, setThemePaletteMode] = useState("light");
  const [customThemeProps, setCustomThemeProps] = useState({});

  useEffect(() => {
    let localMode = localStorage.getItem("themePaletteMode");
    if(localMode){
      setThemePaletteMode(localMode);
    } else {
      if (prefersDarkMode) {
        setThemePaletteMode("dark");
      } else {
        setThemePaletteMode("light");
      }
    }
  }, [prefersDarkMode]);

  const toggleThemePaletteMode = useCallback(() => {
    let nextThemePaletteMode = themePaletteMode==="light"?"dark":"light";
    setThemePaletteMode(nextThemePaletteMode);
    if (prefersDarkMode){
      if(nextThemePaletteMode===("dark")){
        localStorage.removeItem("themePaletteMode");
      } else {
        localStorage.setItem("themePaletteMode", nextThemePaletteMode);
      }
    } else {
      if(nextThemePaletteMode===("light")){
        localStorage.removeItem("themePaletteMode");
      } else {
        localStorage.setItem("themePaletteMode", nextThemePaletteMode);
      }
    }

  }, [prefersDarkMode, themePaletteMode]);

  useEffect(() => {
    if (userData) {
      setIsLoading(true);
      getRequest("users-permissions/roles/" + userData.role.id).then(
        (roleResponse) => {
          setRoleData(roleResponse.data.role);
          setIsLoading(false);
        }
      );
    } else {
      setIsAdminPanelOpen(false);
      setEditMode(false);
      // get Public Permissions
      setIsLoading(true);
      getRequest("users-permissions/roles/2").then((roleResponse) => {
        setRoleData(roleResponse.data);
        setIsLoading(false);
      });
    }
  }, [userData]);

  const handleUser = (user) => {
    setUserData(user);
  };

  useEffect(() => {
    if (getToken()) {
      setIsLoading(true);
      getRequest("users/me").then((userResponse) => {
        let user = userResponse.data;
        setUserData(user);
        // get Permissions
        getRequest("users-permissions/roles/" + user.role.id).then(
          (roleResponse) => {
            setRoleData(roleResponse.data.role);
          }
        );
      });
    }
  }, []);

  const checkPermission = useCallback(
    (query = "") => {
      // "role:public,app.event:count,role+:editor"
      // ,app.event:!count ???
      if (roleData && query) {
        const params = query.split(",");
        for (const param of params) {
          const pair = param.split(":");
          const key = pair[0];
          const value = pair[1];
          if (key === "role") {
            return roleData.type === value;
          } else if (key === "role+") {
            return getRoleWeight(value) <= getRoleWeight(roleData.type);
          } else {
            // permission
            let path = key.split(".");
            if (path[0] === "app") {
              path[0] = "application";
            }
            return (
              roleData.permissions[path[0]].controllers[path[1]][value]
                .enabled === true
            );
          }
        }
      }
      return !query;
    },
    [roleData]
  );

  return (
    <AuthContext.Provider
      value={{
        user: userData,
        setUser: handleUser,
        prefersDarkMode,
        themePaletteMode,
        setThemePaletteMode,
        isLoading,
        isAdminPanelOpen,
        setIsAdminPanelOpen,
        editMode,
        setEditMode,
        checkPermission,
        customThemeProps,
        setCustomThemeProps,
        isDesignSidebarOpen,
        setIsDesignSidebarOpen,
        toggleThemePaletteMode
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
