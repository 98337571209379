import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import React from "react";
import { APIBASE } from "../../providers/ApiProvider";
import AdminUploadForm from "./admin-upload-form";

export default function AdminUploadSigle({
  upload,
  onChange = () => {},
  allowAdd = true,
  allowForm = true,
  allowDelete = false,
  format,
  // onSelect = () => {},
}) {
  const [editUpload, setEditUpload] = React.useState();

  const handleClickUpload = () => {
    if (allowForm) setEditUpload(upload);
    // onSelect(upload);
    // if(onListChange) onListChange([...uploads]);
  };

  const handleOnClose = () => {
    setEditUpload(null);
    // onChange(null);
  };

  const handleOpenForm = () => {
    setEditUpload({});
  };

  const handleAdd = (newUpload) => {
    onChange(newUpload);
  };

  const handleChange = (newUpload) => {
    onChange(newUpload);
  };

  const handleDelete = (up1) => {
    // TODO: handle delete
    onChange(undefined);
  };

  return (
    <Card>
      {allowAdd && !upload && (
        <Box>
          <Button onClick={handleOpenForm} color="secondary">
            add
          </Button>
        </Box>
      )}
      {upload && (
        <CardActionArea
          sx={{
            p: 1,
          }}
          onClick={handleClickUpload}
        >
          {/* <Box
              sx={{
                maxHeight: "235px",
                height: "235px",
                textAlign: "center",
              }}
            > */}
          <CardMedia
            key={upload.id}
            component="img"
            // sx={{
            //   maxWidth: "100%",
            //   maxHeight: "100%",
            //   objectFit: "contain",
            //   display: "inline",
            // }}
            image={
              APIBASE +
              (upload?.formats?.[format ? format : "thumbnail"]?.url ||
                upload?.formats?.small?.url ||
                upload.url)
            }
            alt={
              upload.alternativeText
                ? upload.alternativeText
                : "Kein Bild verfügbar.."
            }
          />
          {/* </Box> */}
          <Typography
            variant="subtitle1"
            component="div"
            sx={{
              width: "10rem",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={upload.name}
          >
            {upload.name}
          </Typography>
          <Typography
            variant="subtitle2"
            component="div"
            sx={{ textAlign: "left" }}
          >
            {upload.mime?.split("/")?.[1]} - {upload.width}x{upload.height} -{" "}
            {upload.size}KB
          </Typography>
          <Chip label={upload.mime?.split("/")?.[0]}></Chip>
        </CardActionArea>
      )}
      {allowForm && (
        <AdminUploadForm
          upload={editUpload}
          allowDelete={allowDelete}
          onChange={handleChange}
          onRemove={handleDelete}
          onAdd={handleAdd}
          onClose={handleOnClose}
          onDelete={handleDelete}
        />
      )}
    </Card>
  );
}
