import { Box, Button, ButtonGroup, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DynamicComponent from "../components/dynamic-component";
import ShowLoader from "../components/showLoader";
import UrlNotFound from "./url-not-found.page";

import AddIcon from "@mui/icons-material/Add";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import DynamicEditComponent from "../components/dynamic-edit-component";
import FallbackComponent from "../components/fallback-component";
import { useAuthContext } from "../context/AuthContext";
import { usePageDataContext } from "../context/PageDataContext";
import { getRequest, putRequest } from "../providers/ApiProvider";
export const ComponentIdentifier = [
  "page.image",
  "page.link",
  "page.button",
  "page.rich-text",
  "page.iframe",
  "page.image-carousel",
  //"page.icon-container",
  "page.spacer",
  "event.event-search",
];
export const getComponentData = (key) => {
  const newComp = { __component: key };
  switch (key) {
    case "page.image-carousel":
      newComp.gallery = { Images: [], name: "", id: 0 };
      break;
    case "page.image":
      newComp.src = [];
      newComp.align = "center";
      newComp.width = "";
      newComp.height = "";
      break;
    default:
      break;
  }
  return newComp;
};

export default function DynPage() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const { editMode, checkPermission } = useAuthContext();
  const { setPageDataKey } = usePageDataContext();
  const [error, setError] = useState(null);
  const [page, setPage] = useState(null);

  let path = location.pathname
    .split("/")
    .filter((item) => !!item)
    .join("/")
    .toLowerCase();
  if (!path) {
    path = "/";
  }

  useEffect(() => {
    // loadPageData();
    getRequest(
      "pages?url=" +
        path +
        (checkPermission("role+:member") ? "&_publicationState=preview" : "")
    )
      .then((response) => {
        if (response.data?.length) {
          const newPage = response.data[0];
          setPage(newPage);
          setError(null);
          if (newPage.page_data) {
            setPageDataKey(newPage.page_data.name);
          } else {
            setPageDataKey("default");
          }
        } else {
          setError({ message: "Not Found!" });
          setPage(null);
        }
      })
      .catch((error) => {
        setError(error);
      });
  }, [path, setPageDataKey, checkPermission]);

  const savePage = () => {
    putRequest("pages/" + page.id, page)
      .then((response) => {
        setPage(response.data);
        // enqueueSnackbar("Saved!", "success");
      })
      .catch((e) => {
        // enqueueSnackbar("Error!", "error");
      });
  };

  const handleComponentChange = (component, key, value) => {
    // how to handle this global?
    // throw new Error('I crashed!');
    const newComponets = page.content.map((item) =>
      item === component ? { ...item, [key]: value } : item
    );

    setPage({
      ...page,
      content: newComponets,
    });
  };

  const handleComponentSwitch = (component, direction) => {
    const index = page.content.indexOf(component);
    const newComponets = [...page.content];
    var temp = newComponets[index];
    if (direction === 1 && index > 0) {
      newComponets[index] = newComponets[index - 1];
      newComponets[index - 1] = temp;
    } else if (direction === -1 && index < newComponets.length) {
      newComponets[index] = newComponets[index + 1];
      newComponets[index + 1] = temp;
    }
    setPage({
      ...page,
      content: newComponets,
    });
  };

  const handleComponentAdd = (key) => {
    const newComp = getComponentData(key);
    const newComponets = [...page.content, newComp];
    setPage({
      ...page,
      content: newComponets,
    });
  };

  const handleComponentDelete = (component) => {
    const newComponets = page.content.filter((item) => item !== component);
    setPage({
      ...page,
      content: newComponets,
    });
  };

  const resethandler = (component) => {
    const newComponets = page.content.map((item) =>
      item === component ? { ...item } : item
    );

    setPage({
      ...page,
      content: newComponets,
    });
  };
  if (error) {
    return (
      <div>
        <UrlNotFound />
      </div>
    );
  } else if (page) {
    return (
      <Container component="main" maxWidth="lg" sx={{ mb: 4 }}>
        {editMode && (
          <Box
            sx={{ width: "100%", border: "2px solid", mb: 2 }}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            page.id [{page.id}]
            <Button color="inherit" onClick={savePage}>
              <SaveIcon />
            </Button>
          </Box>
        )}
        <Grid container direction="column" spacing={5}>
          {page.content.map((componentData) => {
            return (
              <Grid key={componentData.__component + componentData.id} item>
                {/* <Container maxWidth="md" style={{ textAlign: "left" }}> */}
                <ErrorBoundary
                  FallbackComponent={FallbackComponent}
                  onError={(error1, errorInfo) =>
                    enqueueSnackbar("dyn.page:" + error1.message, {
                      variant: "error",
                    })
                  }
                  onReset={() => resethandler(componentData)}
                >
                  {editMode && (
                    <Box
                      sx={{ width: "100%", border: "2px solid" }}
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      {componentData.__component} [{componentData.id}]
                      <ButtonGroup
                        variant="text"
                        aria-label="text button group"
                        sx={{ alignSelf: "end" }}
                      >
                        <Button
                          color="inherit"
                          onClick={() => handleComponentDelete(componentData)}
                        >
                          <DeleteIcon />
                        </Button>
                        <Button
                          color="inherit"
                          onClick={() =>
                            handleComponentSwitch(componentData, 1)
                          }
                        >
                          <ArrowUpwardIcon />
                        </Button>
                        <Button
                          color="inherit"
                          onClick={() =>
                            handleComponentSwitch(componentData, -1)
                          }
                        >
                          <ArrowDownwardIcon />
                        </Button>
                      </ButtonGroup>
                    </Box>
                  )}
                  {editMode ? (
                    <div style={{ border: "2px solid" }}>
                      <DynamicEditComponent
                        key={componentData.__component + componentData.id}
                        componentData={componentData}
                        handleComponentChange={handleComponentChange}
                      ></DynamicEditComponent>
                    </div>
                  ) : (
                    <DynamicComponent
                      componentData={componentData}
                    ></DynamicComponent>
                  )}
                </ErrorBoundary>
                {/* </Container> */}
              </Grid>
            );
          })}
          {editMode && (
            <Grid item style={{ width: "100%" }}>
              {/* <Stack direction="row" justifyContent="end"> */}
              <ButtonGroup
                variant="text"
                aria-label="text button group"
                sx={{ alignSelf: "end", border: "2px solid" }}
              >
                {ComponentIdentifier.map((cc) => {
                  return (
                    <Button
                      key={cc}
                      color="inherit"
                      onClick={() => handleComponentAdd(cc)}
                    >
                      <AddIcon />
                      {cc}
                    </Button>
                  );
                })}
              </ButtonGroup>

              {/* </Stack> */}
            </Grid>
          )}
        </Grid>
      </Container>
    );
  }
  return (
    <div>
      <ShowLoader />
    </div>
  );
}
