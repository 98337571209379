import React from "react";

import { Button, TextField } from "@mui/material";
import { parse, stringify } from "qs";
import { Link } from "react-router-dom";
import { APIBASE } from "../providers/ApiProvider";
import MDEdit from "./MDEdit";
import SvelteJSONEditor from "./SvelteJSONEditor";
import AdminGallery from "./admin/admin-gallery";
import AdminUploadList from "./admin/admin-upload-list";
import EventSearch from "./event-search";
import IconContainer from "./icon-container";
import { ImageCarousel } from "./image-carousel";

export default function DynamicEditComponent({
  componentData,
  handleComponentChange,
}) {
  // const [content, setContent] = useState({
  //   json: undefined,
  //   text: undefined,
  // });
  const key = componentData.__component.split(".")[1];
  switch (key) {
    case "image":
      return (
        <>
          <TextField
            margin="normal"
            label="align"
            value={componentData.align}
            color="secondary"
            onChange={(event) =>
              handleComponentChange(componentData, "align", event.target.value)
            }
          />
          <TextField
            margin="normal"
            label="width"
            value={componentData.width}
            color="secondary"
            onChange={(event) =>
              handleComponentChange(componentData, "width", event.target.value)
            }
          />
          <TextField
            margin="normal"
            label="height"
            value={componentData.height}
            color="secondary"
            onChange={(event) =>
              handleComponentChange(componentData, "height", event.target.value)
            }
          />
          <AdminUploadList
            uploads={componentData.src}
            onListChange={(newImages) => {
              handleComponentChange(componentData, "src", newImages);
            }}
          ></AdminUploadList>
          <div
            style={{
              width: "100%",
              textAlign: componentData.align ? componentData.align : "center",
            }}
          >
            <img
              src={
                APIBASE +
                (componentData.src?.[0]?.formats?.large?.url ||
                  componentData.src?.[0]?.url)
              }
              style={{
                alignSelf: "center",
                width: componentData.width,
                height: componentData.height,
              }}
              alt={componentData.alt}
              title={componentData.title}
            ></img>
          </div>
        </>
      );
    case "link":
      return (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <TextField
              margin="normal"
              label="Name"
              value={componentData.name}
              onChange={(event) =>
                handleComponentChange(componentData, "name", event.target.value)
              }
            />
            <TextField
              margin="normal"
              label="title"
              value={componentData.title}
              onChange={(event) =>
                handleComponentChange(
                  componentData,
                  "title",
                  event.target.value
                )
              }
            />
            <TextField
              margin="normal"
              label="url"
              value={componentData.url}
              onChange={(event) =>
                handleComponentChange(componentData, "url", event.target.value)
              }
            />
          </div>
          <Link title={componentData.title} to={componentData.url}>
            <Button color="inherit">{componentData.name}</Button>
          </Link>
        </div>
      );
    case "button":
      return (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <TextField
              margin="normal"
              label="Name"
              value={componentData.name}
              onChange={(event) =>
                handleComponentChange(componentData, "name", event.target.value)
              }
            />
            <TextField
              margin="normal"
              label="title"
              value={componentData.title}
              onChange={(event) =>
                handleComponentChange(
                  componentData,
                  "title",
                  event.target.value
                )
              }
            />
            <TextField
              margin="normal"
              label="url"
              value={componentData.url}
              onChange={(event) =>
                handleComponentChange(componentData, "url", event.target.value)
              }
            />
          </div>
          <Button
            component={Link}
            title={componentData.title}
            to={componentData.url}
            variant="contained"
            style={{ width: "15rem", backgroundColor: "#F29100" }}
          >
            {componentData.name}
          </Button>
        </div>
      );
    case "rich-text":
      return (
        <div className="container">
          <MDEdit
            value={componentData.content}
            onChange={(value) =>
              handleComponentChange(componentData, "content", value)
            }
            height={500}
          />
        </div>
      );
    case "iframe":
      return (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <TextField
              margin="normal"
              label="title"
              value={componentData.title}
              onChange={(event) =>
                handleComponentChange(
                  componentData,
                  "title",
                  event.target.value
                )
              }
            />
            <TextField
              margin="normal"
              label="src"
              value={componentData.src}
              onChange={(event) =>
                handleComponentChange(componentData, "src", event.target.value)
              }
            />
          </div>
          <iframe
            title={componentData.title}
            width="100%"
            height="440"
            src={componentData.src}
          ></iframe>
        </div>
      );
    case "image-carousel":
      return (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            {/* <AdminUploadList
              uploads={componentData.gallery.Images}
              onListChange={(newImages) => {
                handleComponentChange(componentData, "gallery", {
                  ...componentData.gallery,
                  Images: newImages,
                });
              }}
            /> */}
            <AdminGallery
              gallery={componentData.gallery}
              onChange={(gallery) => {
                handleComponentChange(componentData, "gallery", gallery);
              }}
            ></AdminGallery>
          </div>
          <ImageCarousel gallery={componentData.gallery} />
        </div>
      );
    case "icon-container":
      return (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>icon-container: todo</div>
          <IconContainer iconListId={componentData.icon_list.id} />
        </div>
      );
    case "spacer":
      return (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <TextField
              margin="normal"
              label="height"
              value={componentData.height}
              onChange={(event) =>
                handleComponentChange(
                  componentData,
                  "height",
                  event.target.value
                )
              }
            />
          </div>
          <div style={{ height: componentData.height }}></div>
        </div>
      );
    case "event-search":
      // if(componentData.params){
      //   throw new Error('I crashed!');
      // }

      return (
        <div style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <TextField
              margin="normal"
              label="params"
              value={componentData.params}
              sx={{ width: "100%" }}
              onChange={(event) =>
                handleComponentChange(
                  componentData,
                  "params",
                  event.target.value
                )
              }
            />
            {componentData && (
              <SvelteJSONEditor
                content={
                  componentData.params
                    ? { json: parse(componentData.params) }
                    : {}
                }
                onChange={(event) => {
                  handleComponentChange(
                    componentData,
                    "params",
                    stringify(event.json ? event.json : JSON.parse(event.text))
                  );
                }}
              />
            )}
          </div>
          <EventSearch componentData={componentData} />
        </div>
      );
    default:
      return <span>DynamicComponent not found!</span>;
  }
}
