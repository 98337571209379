import { Container, IconButton, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getRequest } from "../providers/ApiProvider.jsx";
import Icon from "./icon.component.jsx";
import ShowLoader from "./showLoader.jsx";
function IconContainer({ iconListId }) {
  const theme = useTheme();
  const [iconList, setIconList] = useState();
  useEffect(() => {
    if (iconListId) {
      getRequest("icon-lists/" + iconListId)
        .then((response) => {
          if (response.data) {
            setIconList(response.data);
          }
        })
        .catch((error) => {
          // setError(error);
        });
    }
  }, [iconListId]);
  if (iconList) {
    return (
      <Container sx={{ width: "100%", textAlign: "center" }}>
        {iconList.icons.map((icon) => {
          return (
            <Link
              key={icon.id}
              style={{ margin: theme.spacing(4), color: "white" }}
              to={icon.to}
              className="socials"
            >
              <IconButton
                aria-label={icon.aria_label}
                component="span"
                size={icon.size}
              >
                <Icon icon={icon}></Icon>
              </IconButton>
            </Link>
          );
        })}
      </Container>
    );
  }
  return (
    <div>
      <ShowLoader />
    </div>
  );
}

export default IconContainer;
