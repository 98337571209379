import {
  Grid,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import moment from "moment-timezone";
import React from "react";
// import "moment/locale/de";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useEffect, useState } from "react";
import { useAuthContext } from "../context/AuthContext";
import { getRequest } from "../providers/ApiProvider";
import MDEdit from "./MDEdit";
import AdminUploadSigle from "./admin/admin-upload-single";
// moment.locale("de");
export default function EventHappeningEdit({ happening, onChange = () => {} }) {
  const theme = useTheme();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const startTime = moment.utc(happening.start).tz(timezone).format("HH:mm");
  const endTime = moment.utc(happening.end).tz(timezone).format("HH:mm");
  const date = moment.utc(happening.start).tz(timezone).format("YYYY-MM-DD");
  const day = moment(date).format("dddd");
  const smallDisplay = useMediaQuery(theme.breakpoints.down("sm"));

  const [rooms, setRooms] = useState(null);

  const { editMode } = useAuthContext();
  useEffect(() => {
    if (editMode) {
      getRequest("rooms")
        .then((response) => {
          if (response.data?.length) {
            setRooms(response.data);
          }
        })
        .catch((error) => {
          // setError(error);
        });
    }
  }, [editMode]);

  return (
    <Card
      sx={{
        padding: theme.spacing(1),
        marginTop: theme.spacing(5),
      }}
    >
      <Grid container direction={smallDisplay ? "column" : "row"}>
        <Grid item sm={12} md={3}>
          <Grid container direction="row">
            <Grid item md={12}>
              <AdminUploadSigle
                upload={happening.image_banner}
                onChange={(newUpload) =>
                  onChange({ ...happening, image_banner: newUpload })
                }
              ></AdminUploadSigle>
            </Grid>
            <Grid item md={12}>
              <Stack
                direction={smallDisplay ? "row" : "column"}
                spacing={1}
                sx={{ m: 1 }}
              >
                <Chip
                  label={day}
                  size="small"
                  color="primary"
                  sx={{ color: "white" }}
                />
                <Chip
                  label={"Beginn: " + startTime + " Uhr"}
                  size="small"
                  color="success"
                  sx={{ color: "white" }}
                />
                <Chip
                  label={"Ende: " + endTime + " Uhr"}
                  size="small"
                  color="error"
                  sx={{ color: "white" }}
                />
              </Stack>
              <DateTimePicker
                label="start"
                value={moment(happening.start)}
                onChange={(newValue) =>
                  onChange({ ...happening, start: newValue })
                }
              />
              <DateTimePicker
                label="end"
                value={moment(happening.end)}
                onChange={(newValue) =>
                  onChange({ ...happening, end: newValue })
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={12} md={9}>
          <Box>
            <CardContent
              sx={{
                minHeight: theme.spacing(20),
                maxWidth: theme.spacing(120),
                width: "100%",
                paddingTop: 0,
                paddingBottom: "0 !important",
              }}
            >
              <Grid container direction="row">
                <TextField
                  margin="normal"
                  label="name"
                  value={happening.name}
                  onChange={(e) =>
                    onChange({ ...happening, name: e.target.value })
                  }
                />
                <Select
                  value={happening.event_room?.id || 0}
                  label="room"
                  onChange={(event) =>
                    onChange({ ...happening, event_room: event.target.value })
                  }
                  renderValue={() => happening.event_room?.name}
                >
                  {rooms &&
                    rooms.map((room) => {
                      return (
                        <MenuItem key={room.id} value={room}>
                          {room.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </Grid>
              <MDEdit
                value={happening.description}
                onChange={(value) =>
                  onChange({ ...happening, description: value })
                }
                height={500}
              />
            </CardContent>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
