import React from 'react';

import moment from 'moment-timezone';

export const DateTime = ({ datetime }) => {

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const date = moment.utc(datetime).tz(timezone).format('DD.MM.YYYY');
    const time = moment.utc(datetime).tz(timezone).format('HH:mm');
    
    return (
        <span>
            {date} um {time} Uhr
        </span>
    );
};

export default DateTime;