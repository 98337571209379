import { Brush } from "@mui/icons-material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { CustomNavLink } from "../../components/customn-nav-link";
import Icon from "../../components/icon.component";
import { useAuthContext } from "../../context/AuthContext";

const drawerWidth = 240;

const links = [
  { url: "/admin/dashboard", title: "Dashboard", type: "DeveloperBoard" },
  { url: "/admin/users", title: "Users", type: "People" },
  { url: "/admin/links", title: "Links", type: "Link" },
  { url: "/admin/pages", title: "Pages", type: "AutoStories" },
  { url: "/admin/events", title: "Events", type: "Event" },
  { url: "/admin/uploads", title: "Uploads", type: "Upload" },
  // { url: "/admin/design", title: "Design", type: "Brush" },
];

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  top: "64px",
  bottom: "64px",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AdminSidebar() {
  // const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const {
    editMode,
    setEditMode,
    setIsAdminPanelOpen,
    isDesignSidebarOpen,
    setIsDesignSidebarOpen,
  } = useAuthContext();

  const handleToogle = () => {
    setOpen(!open);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleToogle}>
          {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            className={editMode ? "Mui-selected" : undefined}
            onClick={handleEditMode}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <EditIcon color={editMode ? "secondary" : undefined} />
            </ListItemIcon>
            <ListItemText
              primary={"Edit Mode"}
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        <Divider />
        {links.map((data, index) => (
          <ListItem key={data.url} disablePadding sx={{ display: "block" }}>
            <ListItemButton
              component={CustomNavLink}
              to={data.url}
              activeclass="Mui-selected"
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <IconButton
                  // activestyle={{ color: "#ffffff" }}
                  component="span"
                  // size="lg"
                >
                  <Icon icon={data}></Icon>
                </IconButton>
              </ListItemIcon>
              <ListItemText
                primary={data.title}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
        <Divider />
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            onClick={() => setIsDesignSidebarOpen(!isDesignSidebarOpen)}
            activeclass="Mui-selected"
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <IconButton
                // activestyle={{ color: "#ffffff" }}
                component="span"
                // size="lg"
              >
                <Brush></Brush>
              </IconButton>
            </ListItemIcon>
            <ListItemText primary={"Design"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider sx={{ marginTop: "auto" }} />
      <DrawerFooter>
        <IconButton onClick={() => setIsAdminPanelOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DrawerFooter>
    </Drawer>
  );
}
